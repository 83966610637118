import Entity from './Entity'

import WebAssets from './WebAssets'
import Client from './Client'
import Credential from './Credential'
import Event from './Event'
import Order from './Order'
import Seating from './Seating'
import Festival from './Festival'
import Venue from './Venue'
import Transfer from './Transfer'

export default class Ticket extends Entity {
  attendee
  barcode
  description
  id
  price
  isTransferable
  allowPrintingMobileTickets

  client
  credential
  event
  festival
  order
  orderReference
  seating
  venue
  webAssets
  transfer

  constructor(initialValues = {}) {
    super(initialValues)

    const {
      client,
      credential,
      event,
      festival,
      order,
      seating,
      venue,
      webAssets,
      transfer,
    } = initialValues

    this.client = new Client(client)
    this.credential = new Credential(credential)
    this.event = new Event(event)
    this.festival = new Festival(festival)
    this.order = new Order(order)
    if (seating !== undefined) {
      this.seating = new Seating(seating)
    }
    this.venue = new Venue(venue)
    this.webAssets = new WebAssets(webAssets)
    this.transfer = new Transfer(transfer)
    this.hasTransfer = () => {
      const { transfer: { status: transferStatus } = {} } = this
      return transferStatus !== undefined
    }
  }
}
