import React from 'react'
import { PropTypes } from 'prop-types'
import { Checkbox as SemanticCheckbox } from 'semantic-ui-react'

export const Checkbox = props => {
  return <SemanticCheckbox {...props} />
}

Checkbox.propTypes = {
  label: PropTypes.node,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  checked: PropTypes.bool,
  defaultChecked: PropTypes.bool,
  disabled: PropTypes.bool,
}
