export default class ClassDecorator {
  static makeSingleton(target) {
    let instance
    const handler = {
      construct: function(target, args) {
        if (!instance) {
          instance = new target(...args)
        }
        return instance
      },
    }
    return new Proxy(target, handler)
  }
}
