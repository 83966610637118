import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Modal } from './Modal'
import { useErrorBoundary } from '../hooks/error'

export const ActionModal = ({ trigger, content }) => {
  const { confirm, complete } = content

  const [confirmed, setConfirmed] = useState()
  const [processing, setProcessing] = useState()

  const setException = useErrorBoundary()

  const handleConfirm = async (...args) => {
    setProcessing(true)
    try {
      const success = await confirm.onConfirm.apply(this, args)
      setConfirmed(success !== false)
    } catch (e) {
      setException(e)
    }
    setProcessing(false)
    return true
  }

  const handleComplete = (...args) => {
    complete.onConfirm.apply(this, args)
    setProcessing(false)
    setConfirmed()
  }

  const actionConfirmationModalProps = {
    ...confirm,
    onConfirm: handleConfirm,
    closeOnConfirm: false,
  }

  const actionCompletedModalProps = {
    ...complete,
    onConfirm: handleComplete,
    onCancel: handleComplete,
  }

  const modalProps = {
    ...(confirmed !== true
      ? actionConfirmationModalProps
      : actionCompletedModalProps),
    trigger,
    maskWithLoader: processing === true,
  }

  return <Modal {...modalProps} />
}

ActionModal.propTypes = {
  trigger: PropTypes.node.isRequired,
  content: PropTypes.shape({
    confirm: PropTypes.shape(Modal.propTypes),
    complete: PropTypes.shape(Modal.propTypes),
  }).isRequired,
}
