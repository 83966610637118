export default class ArraySummary {
  getMap(data = []) {
    let summary = data.reduce(
      (summary, val) => summary.set(val, 1 + (summary.get(val) || 0)),
      new Map(),
    )
    return summary
  }

  getFormattedWithQuantityArray(data = []) {
    let summary = this.getMap(data)
    let response = []
    summary.forEach(function(value, key) {
      response.push(key + (value > 1 ? ' (' + value + ')' : ''))
    })
    return response
  }
}
