/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import PropTypes from 'prop-types'

import ConfirmationIcon from '../../../../MyAccount/adapters/presenters/react/ConfirmationIcon'

export const ConfirmationMessageBody = ({
  title = 'You are all set',
  message,
}) => {
  return (
    <CenterLayout>
      <ConfirmationIcon />
      <TitleLayout>{title}</TitleLayout>
      <MessageLayout>{message}</MessageLayout>
    </CenterLayout>
  )
}

ConfirmationMessageBody.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
}

const CenterLayout = props => (
  <div
    css={css`
      text-align: center;
      margin: 2rem auto;

      svg {
        margin-bottom: 2rem;
        width: 4rem;
        height: 4rem;
      }
    `}
    {...props}
  />
)

const TitleLayout = props => (
  <div
    css={css`
      font-weight: bold;
    `}
    {...props}
  />
)

const MessageLayout = props => <div {...props} />
