import React from 'react'
import { Image as SemanticImage } from 'semantic-ui-react'
import { PropTypes } from 'prop-types'

export const Image = ({ src = '', alt = '', ...props }) =>
  src ? <SemanticImage {...{ src, alt, ...props }} /> : null

Image.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
  ]),
}
