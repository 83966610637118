import React from 'react'
import PrintIcon from './PrintIcon'

export const PrintTicket = ({ handlePrint }) => {

  return (
      <span
          className="print-icon"
          onKeyPress={(event, ...args) => {
            var code = event.charCode || event.keyCode
            if (code === 13) {
              handlePrint();
            }
          }}
          onClick={ () => {
              handlePrint();
          }}
      >
            <PrintIcon alt="print ticket" title="print ticket"/>
      </span>
  )
}

export default PrintTicket
