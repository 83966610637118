import { css } from '@emotion/core'

export const cardStyle = css`
    .ui.card& {
    box-shadow: none;
    }
    width: 294px;
    display: inline-block;
    vertical-align: top;
    .legal{
        display:none;
    }
    .order-details{
        display:none;
    }
    .print-icon{
        padding: 0.2rem;
        background: #fff;
        border-radius: 4px;
        line-height: 1;
        display: inline-flex;
        border:1px solid #ccc;
    }
`
