import React from 'react'
import { PropTypes } from 'prop-types'
import { Link } from 'react-router-dom'
import { Segment } from 'Elevate/adapters/presenters/react/Segment'
import { Divider } from 'semantic-ui-react'
import { Container } from '../../../../../Elevate/adapters/presenters/react/Container.js'
import { ContentTitle } from '../ContentTitle'
import { ContentHeader } from '../ContentHeader'
import { Button } from 'Elevate/adapters/presenters/react/Form/Button'
import ConfirmationIcon from '../ConfirmationIcon'
import { TransferDownIcon } from '../TransferDownIcon'
import ArraySummary from '../../../../services/ArraySummary'
import Ticket from '../../../../entities/Ticket'

export const Confirmation = ({
  selectedTickets = new Map(),
  recipient,
  orderReference,
}) => {
  return (
    <Container textAlign="center">
      <ConfirmationIcon />
      <ContentTitle>You are all set!</ContentTitle>
      <Segment basic>
        <RenderSelectedTickets {...{ selectedTickets }} />
        <br />
        <TransferDownIcon />
        <Divider hidden />
        {recipient.firstName} {recipient.lastName}
        <br />
        {recipient.email}
      </Segment>
      <Divider hidden />
      <Segment basic textAlign="left">
        <ContentHeader size="small">What happens next?</ContentHeader>
        After the recipient accepts your ticket transfer, your original tickets
        will no longer be valid. You can cancel the transfer anytime before it
        is accepted.
      </Segment>
      <Segment basic>
        <Link to={`/orders/${orderReference}/tickets`} tabIndex={-1}>
          <Button primary fluid>
            Done
          </Button>
        </Link>
      </Segment>
    </Container>
  )
}

const RenderSelectedTickets = ({ selectedTickets = new Map() }) => {
  const credentialList = []
  if (selectedTickets.size) {
    selectedTickets.forEach((ticket = new Ticket(), id) => {
      const { name: credentialName = '' } = ticket.credential
      credentialList.push(credentialName)
    })
  }
  const summary = new ArraySummary().getFormattedWithQuantityArray(
    credentialList,
  )
  return (
    <div>
      {summary.map(credential => (
        <div key={credential}>{credential}</div>
      ))}
    </div>
  )
}

Confirmation.propTypes = {
  selectedTickets: PropTypes.shape({ forEach: PropTypes.func }),
  recipient: PropTypes.object,
  orderReference: PropTypes.string,
}
