/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { useState } from 'react'
import { PropTypes } from 'prop-types'
import { Accordion as SemanticAccordion, Icon } from 'semantic-ui-react'

export const Dropframe = ({
  expanded = false,
  itemTitle,
  children,
  ...props
}) => {
  const [isExpanded, setExpanded] = useState(expanded)
  const icon = isExpanded === false ? 'angle down' : 'angle up'

  const handleClick = (e, titleProps) => {
    const { index } = titleProps
    const toggle = index === 1 ? true : false

    setExpanded(toggle ? !isExpanded : isExpanded)
  }

  return (
    <SemanticAccordion>
      <SemanticAccordion.Title active index={1} onClick={handleClick}>
        {itemTitle}
        <Icon name={icon} />
      </SemanticAccordion.Title>
      <DropframeContentLayout {...{ active: isExpanded }}>
        {children}
      </DropframeContentLayout>
    </SemanticAccordion>
  )
}

Dropframe.propTypes = {
  itemTitle: PropTypes.string,
  expanded: PropTypes.bool,
  children: PropTypes.node,
}

const DropframeContentLayout = props => (
  <SemanticAccordion.Content
    css={css`
      .content.active& {
        border: 1px solid #ccc;
        border-radius: 3px;
        max-width: 320px;
        background-color: #fff;

        // TODO - enable this absolute position with a page level click/blur handler to close the dropframe
        //position: absolute;
      }

      .ui.accordion:not(.styled) .title~.content:not(.ui)& {
        padding: 0;
      }
    `}
    {...props}
  />
)

DropframeContentLayout.propTypes = {
  children: PropTypes.node,
}
