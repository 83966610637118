import React from 'react'
import { PropTypes } from 'prop-types'
import { Icon } from 'semantic-ui-react'

// TODO: Rename this to ExpansionControl or Expander
export const Toggle = ({
  handleToggle,
  toggleState = 'down',
  inverted = false,
  circular = false,
}) => {
  const props = { inverted, circular }

  if (circular === true) {
    props.size = 'small'
  }

  return (
    <span
      onKeyPress={(event, ...args) => {
        var code = event.charCode || event.keyCode
        if (code === 13) {
          handleToggle.call(this, event, ...args)
        }
      }}
      onClick={handleToggle}
    >
      <Icon
        link
        tabIndex="-1"
        color="blue"
        name={`angle ${toggleState}`}
        bordered={false}
        disabled={circular && toggleState === 'down'}
        style={{ margin: 0, outline: 'none' }}
        {...props}
      />
    </span>
  )
}

Toggle.Circular = props => (
  <Toggle
    {...{
      inverted: true,
      ...props,
      circular: true,
    }}
  />
)

Toggle.propTypes = {
  handleToggle: PropTypes.func,
  toggleState: PropTypes.oneOf(['up', 'right', 'down', 'left']),
  inverted: PropTypes.bool,
  circular: PropTypes.bool,
}
