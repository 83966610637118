/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { PropTypes } from 'prop-types'
import { Form } from 'semantic-ui-react'
import { ErrorImage } from 'Elevate/adapters/presenters/react/ErrorImage'

export const TextInput = ({
  id,
  type = 'text',
  required,
  label,
  value,
  placeholder,
  errorLabel,
  onChange,
}) => (
  <Form.Field {...{ required }}>
    <label>{label}</label>
    <input
      {...{
        /* TODO - figure out a comprehensive solution for auto-zoom on input with less than 16px font-size */
        css: css`
          .ui.form input& {
            font-size: 16px;
          }
        `,
      }}
      {...{ id, type, placeholder, value, onChange, required }}
    />
    <Error {...{ errorLabel }} />
  </Form.Field>
)

const Error = ({ errorLabel }) => {
  return errorLabel ? (
    <div
      css={css`
        color: #d34c46;
        display: flex;
      `}
    >
      <ErrorImage />
      <p>{errorLabel}</p>
    </div>
  ) : null
}

TextInput.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  required: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  errorLabel: PropTypes.string,
  onChange: PropTypes.func,
}
