import React, { useEffect, useState } from 'react'
import { PropTypes } from 'prop-types'
import { Image } from '../Image'
import QRCodeGenerator from '../../../drivers/QRCodeGenerator'
import { List } from 'semantic-ui-react'
import { Segment } from 'Elevate/adapters/presenters/react/Segment'

export const QRCode = ({
  barcode = 'Invalid Barcode',
  altText = 'QR Code',
}) => {
  const [url, setUrl] = useState('')
  const barcodeSize = 140

  useEffect(() => {
    let isMounted = true
    QRCodeGenerator.toDataURL(barcode)
      .then(value => {
        isMounted && setUrl(value)
      })
      .catch(console.error)
    return () => (isMounted = false)
  }, [barcode])

  const imageElement = (
    <Segment
      basic
      textAlign="center"
      size="tiny"
      style={{ position: 'relative' }}
    >
      <List
        size="tiny"
        style={{
          position: 'absolute',
          left: 0,
          right: 0,
          zIndex: 1,
          bottom: 0,
        }}
      >
        <List.Content>{barcode}</List.Content>
      </List>
      <Image
        src={url}
        alt={altText}
        height={barcodeSize}
        width={barcodeSize}
        centered
      ></Image>
    </Segment>
  )

  return imageElement
}

QRCode.propTypes = {
  barcode: PropTypes.string,
  altText: PropTypes.string,
}
