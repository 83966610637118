import Entity from './Entity'

export class Cart extends Entity {
  id
  domain

  constructor(initialValues = {}) {
    super(initialValues)
  }
}
