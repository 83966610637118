/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { PropTypes } from 'prop-types'
import { Header } from 'semantic-ui-react'

export const ContentSubtitle = ({ children }) => (
  <Header
    css={css`
      .ui.header& {
        color: #707070;
        font-size: 20px;
        font-weight: 400;
      }
    `}
    as="h2"
  >
    {children}
  </Header>
)

ContentSubtitle.propTypes = {
  children: PropTypes.node,
}
