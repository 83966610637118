/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { useAuthContext } from 'MyAccount/adapters/presenters/react/hooks/AuthContext'
import { useState, Fragment } from 'react'
import { PropTypes } from 'prop-types'
import { Menu as SemanticMenu, Sidebar } from 'semantic-ui-react'

import { Segment } from 'Elevate/adapters/presenters/react/Segment'
import MenuIcon from './MenuIcon'
import { Link } from '../../../../../Elevate/adapters/presenters/react/Link'
import { ContentHeader } from '../ContentHeader'
import { CloseIcon } from '../../../../../Elevate/adapters/presenters/react/CloseImage'
//TODO: separate the core pieces of this App Menu into a generic Elevate Menu for reuse

export const AppMenu = ({ orderReference, refreshPage }) => {
  const [isActive, setIsActive] = useState()
  return (
    <Fragment>
      <MenuBar onClick={() => setIsActive(true)} />
      {isActive === true && (
        <MenuContent
          {...{ orderReference, refreshPage, isActive, setIsActive }}
        />
      )}
    </Fragment>
  )
}

const MenuBar = ({ onClick }) => (
  <MenuBarLayout>
    <SemanticMenu>
      <SemanticMenu.Item position="right" {...{ onClick }}>
        <MenuIcon />
      </SemanticMenu.Item>
    </SemanticMenu>
  </MenuBarLayout>
)

MenuBar.propTypes = {
  children: PropTypes.node,
}

const MenuBarLayout = ({ children }) => (
  <div
    css={css`
      .ui.menu {
        border-radius: 0px;

        .item {
          cursor: pointer;
        }
      }
    `}
  >
    {children}
  </div>
)

MenuBarLayout.propTypes = {
  children: PropTypes.node,
}

export const MenuContent = ({
  orderReference,
  refreshPage,
  isActive,
  setIsActive,
}) => {
  const orderLink = `/orders/${orderReference}/tickets`
  const ticketTransferLink = `/orders/${orderReference}/tickets/transfer`
  const pendingTransferLink = `/orders/${orderReference}/tickets/transfer/pending`
  const completedTransferLink = `/orders/${orderReference}/tickets/transfer/completed`
  const { setLogoutState } = useAuthContext()

  const setInactive = () => setIsActive(false)
  const MenuLink = ({ children, to, onClick, ...props }) => (
    <SemanticMenu.Item
      as={to ? Link : null}
      onClick={() => {
        if (typeof onClick === 'function') {
          onClick()
        }
        setInactive()
      }}
      {...{ to, ...props }}
    >
      <ContentHeader>{children}</ContentHeader>
    </SemanticMenu.Item>
  )

  return (
    <Sidebar
      as={SemanticMenu}
      borderless
      vertical
      animation="overlay"
      onHide={setInactive}
      visible={isActive}
      direction="right"
    >
      <Segment basic>
        <MenuContentLayout>
          <CloseMenu onClick={setInactive} />
          <MenuLink to={orderLink}>Tickets</MenuLink>
          <MenuLink
            to={ticketTransferLink}
            onClick={() => {
              refreshPage(ticketTransferLink)
              setInactive()
            }}
          >
            Transfer Tickets
          </MenuLink>
          <MenuLink to={pendingTransferLink}>Pending Transfers</MenuLink>
          <MenuLink to={completedTransferLink}>Completed Transfers</MenuLink>
          <MenuLink onClick={() => setLogoutState(true)}>Logout</MenuLink>
        </MenuContentLayout>
      </Segment>
    </Sidebar>
  )
}

MenuContent.propTypes = {
  orderReference: PropTypes.string,
  onClick: PropTypes.func,
}

const MenuContentLayout = ({ children }) => (
  <div
    css={css`
      & a {
        color: inherit;
      }
    `}
  >
    {children}
  </div>
)

MenuContentLayout.propTypes = {
  children: PropTypes.node,
}

const CloseMenu = ({ onClick }) => (
  <div
    css={css`
      cursor: pointer;
      margin: 6px;
      text-align: right;

      & > * {
        filter: invert(100%);
      }
    `}
    {...{ onClick }}
  >
    <CloseIcon />
  </div>
)

const SubMenuLink = ({ children, ...props }) => (
  <div
    css={css`
      margin: 12px;
    `}
  >
    <Link {...props}>{children}</Link>
  </div>
)

SubMenuLink.propTypes = {
  children: PropTypes.node,
}
