import React from 'react'
import { Segment as SemanticSegment } from 'semantic-ui-react'
import { node } from 'prop-types'

export const Segment = ({ children, ...props }) => (
  <SemanticSegment {...props}>{children}</SemanticSegment>
)

Segment.propTypes = {
  children: node,
}
