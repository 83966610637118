/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Fragment, useState, useEffect } from 'react'
import { PropTypes } from 'prop-types'
import { Container } from 'Elevate/adapters/presenters/react/Container.js'
import { ContentTitle } from '../ContentTitle'
import { ContentSubtitle } from '../ContentSubtitle'
import { Link } from 'react-router-dom'
import {
  Table,
  TableHeader,
} from 'Elevate/adapters/presenters/react/Table/Table'
import { Button } from 'Elevate/adapters/presenters/react/Form/Button'
import { TRANSFER_STATE_RECIPIENT } from './TransferTickets'
import { ButtonBar } from '../ButtonBar'
import { TransferTicketRows } from './TransferTicketRows'
import { TransferGroupRow } from './TransferGroupRow'

const COLLAPSED_ORDER_SIZE = 10

export const TransferTicketsContent = ({
  tickets = new Map(),
  selectedTickets = new Map(),
  setSelectedTickets,
  orderReference,
  setTransferState = () => {},
}) => {
  tickets = sortTicketsByCredentialName(tickets)
  let collapsedView = tickets.size >= COLLAPSED_ORDER_SIZE ? true : false
  let ticketGroups = new Map()
  if (collapsedView) {
    ticketGroups = createTicketGroups(tickets)
  }

  return tickets.size > 0 ? (
    <Fragment>
      <Container>
        <ContentTitle>Transfer Tickets</ContentTitle>
        <ContentSubtitle>
          Select the tickets you want to transfer
        </ContentSubtitle>
      </Container>
      {collapsedView ? (
        <SelectCollapsedInput
          {...{
            ticketGroups,
            selectedTickets,
            setSelectedTickets,
          }}
        />
      ) : (
        <SelectInput
          {...{
            tickets,
            selectedTickets,
            setSelectedTickets,
          }}
        />
      )}

      <ButtonBar>
        <Link to={`/orders/${orderReference}/tickets`} tabIndex={-1}>
          <Button>Cancel</Button>
        </Link>
        <Button.Primary
          onClick={() => setTransferState(TRANSFER_STATE_RECIPIENT)}
          disabled={selectedTickets.size < 1}
        >
          Continue
        </Button.Primary>
      </ButtonBar>
    </Fragment>
  ) : null
}

TransferTicketsContent.propTypes = {
  tickets: PropTypes.shape({ forEach: PropTypes.func }),
  selectedTickets: PropTypes.shape({
    forEach: PropTypes.func,
    size: PropTypes.number,
  }),
  setSelectedTickets: PropTypes.func,
  orderReference: PropTypes.string,
  setTransferState: PropTypes.func,
}

const SelectInput = ({
  tickets = new Map(),
  selectedTickets,
  setSelectedTickets,
}) => {
  const rows = TransferTicketRows({
    tickets,
    selectedTickets,
    setSelectedTickets,
    handleSelectTicket,
  })

  return rows.length > 0 ? (
    <Fragment>
      <TableHeader
        className={css`
          padding-left: 2.8rem;
        `}
      >
        Tickets
      </TableHeader>
      <Table>
        <Table.Body>{rows}</Table.Body>
      </Table>
    </Fragment>
  ) : null
}

SelectInput.propTypes = {
  tickets: PropTypes.shape({ size: PropTypes.number, forEach: PropTypes.func }),
  setSelectedTickets: PropTypes.func,
}

const SelectCollapsedInput = ({
  ticketGroups = new Map(),
  selectedTickets,
  setSelectedTickets,
}) => {
  const [groupRows, setGroupRows] = useState([])

  useEffect(() => {
    const rows = []

    if (ticketGroups.size > 0) {
      ticketGroups.forEach((groupTickets, groupKey) => {
        rows.push(
          <TransferGroupRow
            key={groupKey}
            {...{
              groupKey,
              groupTickets,
              selectedTickets,
              setSelectedTickets,
              handleSelectTicket,
            }}
          />,
        )
      })
    }

    setGroupRows(rows)
  }, [ticketGroups, selectedTickets, setSelectedTickets])

  return groupRows.length > 0 ? (
    <Fragment>
      <TableHeader
        className={css`
          padding-left: 2.8rem;
        `}
      ></TableHeader>
      <Table>
        <Table.Body>
          <Table.Row
            className={css`
              padding-left: 2.8rem;
            `}
          >
            <Table.Cell textAlign="left">Ticket</Table.Cell>
            <Table.Cell textAlign="right">Quantity</Table.Cell>
          </Table.Row>
          {groupRows}
        </Table.Body>
      </Table>
    </Fragment>
  ) : null
}

const handleSelectTicket = (
  isSelected,
  selectedTickets,
  ticket,
  setSelectedTickets,
) => {
  const newSelection = selectedTickets.set(ticket.id, ticket)
  if (isSelected === false) {
    newSelection.delete(ticket.id)
  }
  setSelectedTickets(new Map(newSelection))
}

function sortTicketsByCredentialName(data) {
  if (data.length <= 1) {
    return data
  }

  return new Map(
    [...data.entries()].sort((firstElement, secondElement) => {
      let firstItem = createItemForComparison(firstElement)
      let secondItem = createItemForComparison(secondElement)

      return firstItem === secondItem ? 0 : firstItem > secondItem ? 1 : -1
    }),
  )
}

function createItemForComparison(element) {
  let comparison = element[1].credential.name.toLowerCase()
  if (element[1].seating !== undefined) {
    comparison += '|' + element[1].seating.section.toString().padStart(10, '0')
    comparison += '|' + element[1].seating.row.toString().padStart(10, '0')
    comparison += '|' + element[1].seating.seat.toString().padStart(10, '0')
  }
  return comparison
}

function createTicketGroups(tickets) {
  let ticketGroups = [...tickets.entries()].reduce((accumulator, ticket) => {
    const { isTransferable = {} } = ticket[1]
    const { credential: { name: credentialName } = {} } = ticket[1]
    if (accumulator.indexOf(credentialName) === -1 && isTransferable) {
      accumulator.push(credentialName)
    }
    return accumulator
  }, [])

  let groupedTickets = new Map()
  ticketGroups.forEach((groupValue, groupKey) => {
    let ticketsGroup = new Map()
    tickets.forEach((value, key) => {
      const { credential: { name: credentialName } = {} } = value
      if (groupValue === credentialName && value.isTransferable) {
        ticketsGroup.set(key, value)
      }
    })
    groupedTickets.set(groupValue, ticketsGroup)
  })

  return groupedTickets
}
