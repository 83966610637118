/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { PropTypes } from 'prop-types'
import { Valuebox } from '../Form/Valuebox'
import { Dropframe } from 'Elevate/adapters/presenters/react/Form/Dropframe'
import Ticket from 'MyAccount/entities/Ticket'

export const TicketDropframe = ({ selectedTickets = new Map() }) => {
  const credentialCountMap = credentialCountMapFromTicketMap(selectedTickets)
  const credentialRows = []

  credentialCountMap.forEach((count, credentialName) => {
    credentialRows.push(
      <CredentialRow key={credentialName} {...{ credentialName, count }} />,
    )
  })

  return (
    <Dropframe itemTitle={`Selected Tickets (${selectedTickets.size})`}>
      {credentialRows}
    </Dropframe>
  )
}

TicketDropframe.propTypes = {
  selectedTickets: PropTypes.shape({
    get: PropTypes.func,
    set: PropTypes.func,
    forEach: PropTypes.func,
    size: PropTypes.number,
  }),
}

function credentialCountMapFromTicketMap(tickets = new Map()) {
  const credentialCountMap = new Map()

  tickets.forEach((ticket = new Ticket(), id) => {
    const { credential: { name: credentialName = '' } = {} } = ticket
    const count = credentialCountMap.get(credentialName) || 0
    credentialCountMap.set(credentialName, count + 1)
  })

  return credentialCountMap
}

const CredentialRow = ({ credentialName, count }) => (
  <CredentialRowLayout>
    <Valuebox>{count}</Valuebox>
    {credentialName}
  </CredentialRowLayout>
)

CredentialRow.propTypes = {
  credentialName: PropTypes.string,
  count: PropTypes.number,
}

const CredentialRowLayout = ({ children }) => (
  <div
    css={css`
      position: relative;
      border: 0px solid #ccc;
      border-bottom-width: 1px;
      padding: 10px 5px;
      font-size: 0.9rem;
      font-weight: bold;

      &:last-child {
        border-bottom-width: 0px;
      }
    `}
  >
    {children}
  </div>
)

CredentialRowLayout.propTypes = {
  children: PropTypes.node,
}
