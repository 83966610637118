import React from 'react'
import PropTypes from 'prop-types'
import { Image } from 'Elevate/adapters/presenters/react/Image'

const TicketLogo = ({
  src,
  alt = 'Ticket Provider Logo',
  style = { margin: '0.4rem 0.2rem' },
}) =>
  src ? <Image src={src} alt={alt} style={style} /> : <div {...{ style }} />

TicketLogo.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  style: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
  ]),
}

export default TicketLogo
