/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { PropTypes } from 'prop-types'
import { Header } from 'semantic-ui-react'

const ContentLabel = ({ children }) => (
  <Header
    sub
    css={css`
      .ui.sub.header& {
        color: #848484;
        font-size: 0.7rem;
      }
    `}
  >
    {children}
  </Header>
)

export default ContentLabel

ContentLabel.propTypes = {
  children: PropTypes.node,
}
