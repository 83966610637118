import React from 'react'
import { PropTypes } from 'prop-types'

const Index = ({ currentIndex = 0, total = 0 }) => (
  <span>{`${Math.min(currentIndex, total)} of ${total}`}</span>
)

export default Index

Index.propTypes = {
  currentIndex: PropTypes.number,
  total: PropTypes.number,
}
