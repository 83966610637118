import React, { createContext, useState, useContext } from 'react'
import { useAuthenticationService } from 'MyAccount/adapters/presenters/react/hooks/authentication'

class AuthState {
  isAuthenticated = false
  setIsAuthenticated = isAuthenticated => {
    this.isAuthenticated = isAuthenticated
  }
  logoutState = false
  setLogoutState = logoutState => {
    this.logoutState = logoutState
  }
}

const AuthContext = createContext(new AuthState())

export const AuthProvider = ({ children }) => {
  const authService = useAuthenticationService()
  const [isAuthenticated, setIsAuthenticated] = useState(
    authService.isAuthenticated(),
  )
  const [logoutState, setLogoutState] = useState(false)

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        setIsAuthenticated,
        logoutState,
        setLogoutState,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuthContext = () => {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error('useAuthContext must be used from within an AuthProvider')
  }
  return context
}
