/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Dimmer } from 'semantic-ui-react'
import PropTypes from 'prop-types'

// TODO - rename this componenent and related files to Dimmer*
export const Mask = ({ active = true, opacity = 0.75, ...props }) => {
  return <StyledDimmer {...{ active, opacity, ...props }} />
}

const StyledDimmer = ({ opacity, ...props }) => (
  <Dimmer
    css={css`
      .ui.dimmer& {
        background-color: rgba(0, 0, 0, ${opacity});
      }
    `}
    {...props}
  />
)

Mask.propType = {
  active: PropTypes.bool,
  opacity: PropTypes.number,
  children: PropTypes.node,
}
