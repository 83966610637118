import PropTypes from 'prop-types'
import QueryString from 'Elevate/services/QueryString'
import { useAuthenticationService, LOGIN } from '../hooks/authentication'

const ACCESS_TOKEN_PARAM = 'accessToken'

export const AuthRedirect = ({ url, action }) => {
  const {
    location,
    location: { pathname },
  } = window

  const authService = useAuthenticationService()
  const queryStringParser = new QueryString()
  const accessToken = queryStringParser.getUrlParam(ACCESS_TOKEN_PARAM)

  let redirectTo = url

  if (accessToken && action === LOGIN) {
    authService.setAccessToken(accessToken)
    const queryString = queryStringParser.stripUrlParam(ACCESS_TOKEN_PARAM)
    redirectTo = pathname + queryString
  }

  location.assign(redirectTo)

  return null
}

AuthRedirect.propTypes = {
  url: PropTypes.string.isRequired,
  action: PropTypes.string,
}
