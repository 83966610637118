/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { PropTypes } from 'prop-types'
import { Header } from 'semantic-ui-react'

export const ContentTitle = ({ children }) => (
  <Header
    css={css`
      .ui.header& {
        color: #555353;
        font-size: 34px;
        font-weight: 900;
      }
    `}
    as="h1"
  >
    {' '}
    {children}
  </Header>
)

ContentTitle.propTypes = {
  children: PropTypes.node,
}
