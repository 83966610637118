import Entity from './Entity'

export default class Event extends Entity {
  endDate
  name
  presenter
  startDate
  termsUrl
  url

  constructor(initialValues = {}) {
    super(initialValues)

    this.startDate = new Date(this.startDate)
    this.endDate = new Date(this.endDate)
  }
}
