export default class AuthenticationRepository {
  constructor(adapter) {
    this._http = adapter
  }

  async getAccessToken(email, password) {
    const body = {
      email,
      password,
    }
    try {
      const response = await this._http.post('/', body)
      return response.data.accessToken
    } catch (e) {
      throw new Error('Authentication failed')
    }
  }
}
