import AppContext from '../../../../services/AppContext'

export const LOGIN = 'login'
export const LOGOUT = 'logout'

export const useAuthenticationService = () =>
  new AppContext({ env: process.env }).authenticationService

export const useLogin = () => (email, password) =>
  new AppContext({ env: process.env }).authenticationService.login(
    email,
    password,
  )
