import Entity from './Entity'

export default class WebAssets extends Entity {
  images

  constructor(initialValues = {}) {
    super(initialValues)

    const { images = {} } = initialValues
    this.images = images
  }
}
