import React, { useState, useEffect } from 'react'
import { anyNonNil as isUUID } from 'is-uuid'
import { useLocation, useParams } from 'react-router-dom'
import { Loader } from 'Elevate/adapters/presenters/react/Loader'
import { Segment } from 'Elevate/adapters/presenters/react/Segment'
import { Page } from './Page'
import Order from '../react/Order/Order'
import TransferTickets from '../react/TicketTransfer/TransferTickets'
import { AuthErrorBoundary } from './AuthErrorBoundary'
import { TransferErrorBoundary } from './TicketTransfer/TransferErrorBoundary'
import { AcceptTransfer } from './TicketTransfer/AcceptTransfer'
import {
  PendingTransfers,
  CompletedTransfers,
} from './TicketTransfer/TransfersByStatus'
import { useTicketsByOrderReference } from './hooks/tickets'
import { AuthRedirect } from './Login/AuthRedirect'
import QueryString from 'Elevate/services/QueryString'
import { useCartByOrderReference } from './hooks/cart'

import {
  useAuthenticationService,
  LOGIN,
  LOGOUT,
} from '../react/hooks/authentication'

export const AuthenticationPage = ({ action = LOGIN }) => {
  const authService = useAuthenticationService()

  if (action === LOGOUT) {
    authService.logout()
  }

  return (
    <Page.Narrow appMenu={false}>
      <AuthenticationCartContent {...{ action }} />
    </Page.Narrow>
  )
}

const AuthenticationCartContent = ({ action }) => {
  const location = useLocation()
  const orderReference = location.pathname.split('/').find(isUUID)
  const cart = useCartByOrderReference(orderReference)
  const cref = new QueryString().getUrlParam('cref')
  cart.id = cref === undefined ? cart.id : cref
  const domain = new QueryString().getUrlParam('domain')
  cart.domain = domain === undefined ? cart.domain : domain

  let url
  if (process.env.NODE_ENV !== 'production') {
    cart.protocol = 'http:'
    cart.domain = 'www.festivalticketing.test:3000'
  } else {
    cart.protocol = 'https:'
  }

  if (cart.domain && cart.id) {
    const urlState = encodeURIComponent(location.pathname)
    url = `${cart.protocol}//${cart.domain}/cart/${cart.id}/auth/redirect?state=${urlState}#action=${action}`
  }

  return url === undefined ? null : (
    <AuthErrorBoundary>
      <AuthRedirect {...{ url, action }} />
    </AuthErrorBoundary>
  )
}

export const OrderPage = ({ orderReference, ...props }) => (
  <Page dark {...{ orderReference }}>
    <Order {...{ orderReference }} />
  </Page>
)

export const TransferPage = () => {
  const { orderReference, transferParam } = useParams()
  const tickets = useTicketsByOrderReference(orderReference)

  const [transferProps, setTransferProps] = useState({})
  useEffect(
    () =>
      setTransferProps({
        orderReference,
        ...getTransferRouteProps(transferParam),
      }),
    [orderReference, transferParam, setTransferProps],
  )

  return (
    <Page.Narrow {...{ orderReference }}>
      <TransferErrorBoundary>
        {tickets === undefined ? (
          <Loader active size="massive" />
        ) : (
          (() => {
            switch (transferProps.status) {
              case PENDING:
                return <PendingTransfers {...{ tickets }} />
              case COMPLETED:
                return <CompletedTransfers {...{ tickets }} />
              default:
                return <TransferTickets {...{ tickets, ...transferProps }} />
            }
          })()
        )}
        <Segment basic />
      </TransferErrorBoundary>
    </Page.Narrow>
  )
}
TransferPage.propTypes = {}

const PENDING = 'pending'
const COMPLETED = 'completed'

function getTransferRouteProps(transferParam) {
  switch (transferParam) {
    case PENDING:
    case COMPLETED:
      return {
        status: transferParam,
      }
    default:
      return {
        defaultSelection: { [transferParam]: true },
      }
  }
}

export const AcceptTransferPage = props => {
  return (
    <Page dark>
      <TransferErrorBoundary>
        <AcceptTransfer
          {...{
            ...props,
          }}
        />
      </TransferErrorBoundary>
    </Page>
  )
}
