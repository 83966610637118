/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { PropTypes } from 'prop-types'
import { Table as SemanticTable } from 'semantic-ui-react'

export const Table = ({ children }) => (
  <SemanticTable
    striped
    unstackable
    css={css`
      .ui.table& {
        border-radius: 0;
      }
    `}
  >
    {children}
  </SemanticTable>
)

Table.propTypes = {
  children: PropTypes.node,
}

export const TableHeader = ({ className, children }) => (
  <div
    css={css`
      margin-top: 5rem;
      border: 1px solid transparent;
      padding-left: 11px;
      ${className}
    `}
  >
    {children}
  </div>
)

TableHeader.propTypes = {
  children: PropTypes.node,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

Table.Body = ({ children }) => (
  <SemanticTable.Body>{children}</SemanticTable.Body>
)

Table.Row = ({ children }) => <SemanticTable.Row>{children}</SemanticTable.Row>

Table.propTypes = Table.Body.propTypes = Table.Row.propTypes = {
  children: PropTypes.node,
}

Table.Cell = ({ textAlign = 'left', ...props }) => (
  <SemanticTable.Cell {...{ textAlign, ...props }} />
)

Table.Cell.propTypes = {
  textAlign: PropTypes.oneOf(['left', 'right']),
  children: PropTypes.node,
}
