import { useState, useEffect } from 'react'

import AppContext from '../../../../services/AppContext'

export const useAppContext = () => {
  const [appContext, initAppContext] = useState({})

  useEffect(() => initAppContext(new AppContext({ env: process.env })), [])

  return appContext
}
