import React from 'react'
import PropTypes from 'prop-types'
import { useState, useEffect } from 'react'
import { Loader } from 'Elevate/adapters/presenters/react/Loader'
import { Redirect } from 'react-router-dom'
import {
  AcceptTransferException,
  AcceptTransferCanceledException,
} from './TransferException'
import { AccessException } from '../Exception'
import { useTicketService } from '../hooks/tickets'

export const AcceptTransfer = ({ ticketTransferId }) => {
  const ticketService = useTicketService()
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(false)

  if (error) {
    const { response: { data: { status } = {} } = {} } = error
    if (error instanceof AccessException) {
      throw error
    } else if (status === 400) {
      throw new AcceptTransferCanceledException()
    } else {
      throw new AcceptTransferException()
    }
  }

  useEffect(() => {
    if (isLoading && ticketTransferId !== undefined) {
      ticketService
        .acceptTransfer(ticketTransferId, 'accepted')
        .catch(error => setError(error))
        .then(({ status } = {}) => {
          setIsLoading(status === undefined)
        })
    }
  }, [isLoading, ticketTransferId, ticketService])

  return isLoading ? (
    <Loader active size="massive" />
  ) : (
    <Redirect to={`/orders/${ticketTransferId}/tickets`} />
  )
}

AcceptTransfer.propTypes = {
  acceptTransfer: PropTypes.func,
  ticketTransferId: PropTypes.string,
}
