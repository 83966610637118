import React from 'react'
import { PropTypes } from 'prop-types'
import ContentLabel from '../ContentLabel'
import { Grid } from 'Elevate/adapters/presenters/react/Grid'

export const LabeledGridRow = ({
  textAlign = 'left',
  width = 16,
  label = '',
  className,
  children,
}) => {
  return (
    <Grid.Row>
      <Grid.Column {...{ textAlign, width, className }}>
        <ContentLabel>{label}</ContentLabel>
        {children}
      </Grid.Column>
    </Grid.Row>
  )
}

LabeledGridRow.propTypes = {
  textAlign: PropTypes.string,
  width: PropTypes.number,
  label: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
}
