// Functions should be listed alphabetically and have unit test coverage

export function createMapFromArrayOfObjects(objects = [], objectKey = 'id') {
  const newMap = new Map()
  objects.forEach(object => newMap.set(object[objectKey], object))
  return newMap
}

export function createArrayOfKeysFromMap(mapItems = new Map()) {
  const items = []
  mapItems.forEach((value, key) => {
    items.push(key)
  })
  return items
}

export function createIntersectionOfArrays(items = [], array = []) {
  return items.filter(item => array.includes(item))
}

export function isArraySubsetOfArray(items = [], array = []) {
  let intersect = items.filter(item => array.includes(item))
  return items.length === intersect.length
}
