/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { useState } from 'react'
import PropTypes from 'prop-types'
import { Modal as SemanticModal } from 'semantic-ui-react'

import { ContentModalTitle } from 'MyAccount/adapters/presenters/react/ContentModalTitle'
import { ContentModalSubtitle } from 'MyAccount/adapters/presenters/react/ContentModalSubtitle'
import { Mask } from '../Mask'
import { ButtonBar } from 'MyAccount/adapters/presenters/react/ButtonBar'
import { Button } from '../Form/Button'
import { Loader } from '../Loader'

export const Modal = ({
  defaultOpen = false,
  trigger,
  title,
  subtitle,
  body,
  labelConfirm,
  onConfirm = () => {},
  labelCancel,
  onCancel = () => {},
  closeOnConfirm = true,
  maskWithLoader,
}) => {
  const [isOpen, setOpen] = useState(defaultOpen)

  const handleOpen = () => setOpen(true)

  const handleClose = (event, data, ...args) => {
    if (maskWithLoader) {
      event.preventDefault()
    } else {
      data.children === labelConfirm ? onConfirm() : onCancel()
      if (closeOnConfirm || data.children !== labelConfirm) {
        setOpen(false)
      }
    }
  }

  return (
    <SemanticModal
      open={isOpen}
      onClose={handleClose}
      onOpen={handleOpen}
      {...{ trigger }}
      style={{ maxWidth: 350 }}
      closeOnDimmerClick={maskWithLoader !== true}
    >
      <SemanticModal.Content>
        <ContentModalTitle>{title}</ContentModalTitle>
        <ContentModalSubtitle>{subtitle}</ContentModalSubtitle>
        {body}
        {maskWithLoader === true && (
          <LoaderLayout>
            <Loader active />
          </LoaderLayout>
        )}
        <ButtonBar>
          <Button.Fluid primary onClick={handleClose}>
            {labelConfirm}
          </Button.Fluid>
          {labelCancel ? (
            <Button.Fluid onClick={handleClose}>{labelCancel}</Button.Fluid>
          ) : null}
        </ButtonBar>
        <Mask active={maskWithLoader === true} opacity={0.3} />
      </SemanticModal.Content>
    </SemanticModal>
  )
}

Modal.propTypes = {
  trigger: PropTypes.node,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  body: PropTypes.node,
  labelConfirm: PropTypes.string,
  onConfirm: PropTypes.func,
  labelCancel: PropTypes.string,
  onCancel: PropTypes.func,
  maskWithLoader: PropTypes.bool,
}

const LoaderLayout = props => (
  <div
    css={css`
      filter: brightness(0.7);
    `}
    {...props}
  />
)
