/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useState, useEffect } from 'react'
import { PropTypes } from 'prop-types'

import { TransferTicketsContent } from './TransferTicketsContent'
import { Confirmation } from '../TicketTransfer/Confirmation'
import { TransferTicketsRecipient } from './TransferTicketsRecipient'
import { TransferErrorBoundary } from './TransferErrorBoundary'

const TransferTickets = ({
  tickets,
  orderReference,
  initialTransferState = TRANSFER_STATE_SELECTION,
  defaultSelection = {},
}) => {
  const [selectedTickets, setSelectedTickets] = useState()

  const [transferState, setTransferState] = useState(initialTransferState)
  const [recipient, setRecipient] = useState({
    firstName: '',
    lastName: '',
    email: '',
  })

  useEffect(() => {
    if (tickets) {
      setDefaultSelectedTickets(tickets, defaultSelection, setSelectedTickets)
    }
  }, [tickets, defaultSelection, setSelectedTickets])

  if (selectedTickets === undefined) {
    return null
  }

  return (
    <TransferErrorBoundary>
      {(() => {
        switch (transferState) {
          case TRANSFER_STATE_RECIPIENT:
            return (
              <TransferTicketsRecipient
                {...{
                  selectedTickets,
                  setTransferState,
                  recipient,
                  setRecipient,
                  orderReference,
                }}
              />
            )
          case TRANSFER_STATE_CONFIRMATION:
            return (
              <Confirmation
                {...{ selectedTickets, recipient, orderReference }}
              />
            )
          case TRANSFER_STATE_SELECTION:
          default:
            return (
              <TransferTicketsContent
                {...{
                  tickets,
                  selectedTickets,
                  setTransferState,
                  setSelectedTickets,
                  orderReference,
                }}
              />
            )
        }
      })()}
    </TransferErrorBoundary>
  )
}
export default TransferTickets

export const TRANSFER_STATE_SELECTION = Symbol('selection')
export const TRANSFER_STATE_RECIPIENT = Symbol('recipient')
export const TRANSFER_STATE_CONFIRMATION = Symbol('confirmation')

TransferTickets.propTypes = {
  tickets: PropTypes.shape({
    size: PropTypes.number,
    has: PropTypes.func,
    get: PropTypes.func,
    set: PropTypes.func,
  }),
  initialTransferState: PropTypes.instanceOf(Symbol),
  orderReference: PropTypes.string,
  defaultSelection: PropTypes.object,
}

function setDefaultSelectedTickets(
  tickets = new Map(),
  defaultSelection,
  setSelectedTickets,
) {
  if (tickets.size > 0) {
    const selectedTickets = new Map()
    Object.keys(defaultSelection).forEach(ticketId => {
      if (
        tickets.has(ticketId) &&
        tickets.get(ticketId).hasTransfer() !== true
      ) {
        selectedTickets.set(ticketId, tickets.get(ticketId))
      }
    })
    setSelectedTickets(selectedTickets)
  }
}
