/** @jsx jsx */
import { jsx } from '@emotion/core'
import { cardStyle } from './Ticket.style'
import { useState } from 'react'
import PropTypes from 'prop-types'
import { Card } from 'Elevate/adapters/presenters/react/Card/Card'
import TicketDateFormatter from '../../../../services/TicketDateFormatter'
import CurrencyFormatter from '../../../../services/CurrencyFormatter'
import TicketEntity from '../../../../entities/Ticket'
import TicketBack from './TicketBack'
import TicketFront from './TicketFront'

const Ticket = ({
  ticket = new TicketEntity(),
  ticketService = {},
  totalTickets,
  ticketIndex,
  expanded = false,
  printable = false,
} = {}) => {
  const [showBack, setShowBack] = useState(expanded)
  const [showPrint, setShowPrint] = useState(printable)
  const ticketID = "ticket-"+ticket.id;
  const printClass = showPrint ? 'printable' : 'not-printable';

  const mapTicketEntityToTicketBackProps = ticketEntity => ({
    orderId: ticketEntity.order.id,
    attendee: ticketEntity.attendee,
    description: ticketEntity.description,
    price: CurrencyFormatter.formatCentsAsCurrency(ticketEntity.price),
    eventUrl: ticketEntity.event.url,
    termsUrl: ticketEntity.event.termsUrl,
    legal: ticketEntity.event.legal,
  })

  const [ticketBackProps, setTicketBackProps] = useState({
    ...mapTicketEntityToTicketBackProps(ticket),
    fetchData: () => {
      setTicketBackProps({ isLoading: true })
      ticketService
        .getTicketById(ticket.id)
        .catch(() => {})
        .then(setTicketBackPropsFromEntity)
    },
  })

  const setTicketBackPropsFromEntity = ticketEntity => {
    const props = mapTicketEntityToTicketBackProps(ticketEntity)
    if (props.orderId) {
      setTicketBackProps(props)
    } else {
      setTicketBackProps({ isLoading: false })
    }
  }

  const ticketCard = (
    <Card css={cardStyle} className={`${printClass} ${ticketID}`}>
      <Card.Content>
        <TicketFront
          {...{
            id: ticket.id,
            credentialName: ticket.credential.name,
            venueName: ticket.venue.name,
            seating: ticket.seating,
            barcode: ticket.barcode,
            eventName: ticket.event.name,
            eventPresenter: ticket.event.presenter,
            eventDates: TicketDateFormatter.formatTicketDates(
              ticket.event.startDate,
              ticket.event.endDate,
            ),
            images: ticket.webAssets.images,
            apiUrl: ticketService.serverUrl,
            isIOSDevice: isIOSDevice(),
            showBack: showBack,
            setShowBack: setShowBack,
            showPrint: showPrint,
            setShowPrint: setShowPrint,
            printable: printable,
            totalTickets: totalTickets,
            ticketIndex: ticketIndex,
            orderReference: ticket.orderReference,
            isTransferable: ticket.isTransferable,
            allowPrintingMobileTickets: ticket.event.allowPrintingMobileTickets,
            transfer: ticket.transfer,
          }}
        />
      </Card.Content>
      {showBack ? (
        <Card.Content className="ticket-back">
          <TicketBack {...ticketBackProps} />
        </Card.Content>
      ) : null}
    </Card>
  )

  return ticketCard
}

function isIOSDevice() {
  return /iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
}

export default Ticket

Ticket.propTypes = {
  ticket: PropTypes.instanceOf(TicketEntity),
  ticketIndex: PropTypes.number,
  totalTickets: PropTypes.number,
  ticketService: PropTypes.object,
  expanded: PropTypes.bool,
  printable: PropTypes.bool,
}
