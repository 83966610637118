import { Cart } from 'MyAccount/entities/Cart'

export class CartRepository {
  constructor(http) {
    this._http = http
  }

  async getCartByOrderReference(orderReference) {
    const response = await this._http.get(`/carts/${orderReference}`)
    return new Cart(response.data)
  }
}
