import Ticket from '../../entities/Ticket'

export default class TicketRepository {
  constructor(adapter) {
    this._data = []
    this._mapper = new TicketMapper(adapter)
  }

  async search(query = {}) {
    let tickets
    try {
      tickets = await this._mapper.fetch(query)
    } catch (e) {
      throw e
    }
    this._data = tickets
    return tickets
  }

  async find(query = {}) {
    const ticket = await this._mapper.fetch(query)
    this._data = ticket
    return ticket
  }
}

class TicketMapper {
  constructor(adapter) {
    this._adapter = adapter
  }

  async fetch(query = {}) {
    if ({ ...query }.hasOwnProperty('orderReference')) {
      return this._getTicketsByOrderReference(query.orderReference)
    } else if ({ ...query }.hasOwnProperty('id')) {
      return this._getTicketById(query.id)
    } else {
      throw new Error('this search route has not been implemented yet')
    }
  }

  async _getTicketsByOrderReference(orderReference) {
    let response,
      fullTicketsResponse,
      pageNum = 1
    try {
      fullTicketsResponse = []
      while (pageNum != null) {
        const url = `/byOrder?order=${orderReference}&page=${pageNum}`
        response = await this._adapter.get(url)
        if (
          response &&
          response.headers &&
          response.headers['next-page-number']
        ) {
          pageNum = response.headers['next-page-number']
        } else {
          pageNum = null
        }
        fullTicketsResponse = fullTicketsResponse.concat(response.data)
      }
      return this._mapFetchedDataToTickets(fullTicketsResponse, { orderReference })
    } catch (e) {
      this._handleError(e)
      return { data: [] }
    }
  }

  _handleError(error) {
    // TODO: figure out what we want to do with error conditions
    throw error
  }

  async _getTicketById(id) {
    const response = await this._adapter.get(`/${id}`).catch(error => {
      this._handleError(error)
      return { data: {} }
    })
    return new Ticket(response.data)
  }

  _mapFetchedDataToTickets(fetchedData, defaultValues) {
    const sortedData = this._sortFetchedTicketsByStatus(fetchedData)
    return sortedData.map(data => new Ticket({ ...defaultValues, ...data }))
  }

  _sortFetchedTicketsByStatus(tickets = []) {
    const sortedArray = []

    const availableTickets = tickets.filter(ticket => !ticket.transfer)
    const pendingTickets = tickets.filter(
      ticket => ticket.transfer && ticket.transfer.status === 'pending',
    )
    const confirmedTickets = tickets.filter(
      ticket => ticket.transfer && ticket.transfer.status === 'accepted',
    )

    if (availableTickets)
      availableTickets.forEach(ticket => sortedArray.push(ticket))

    if (pendingTickets)
      pendingTickets.forEach(ticket => sortedArray.push(ticket))

    if (confirmedTickets)
      confirmedTickets.forEach(ticket => sortedArray.push(ticket))

    return sortedArray
  }
}
