import React, { useState, useEffect } from 'react'
import { PropTypes } from 'prop-types'
import { Checkbox } from 'Elevate/adapters/presenters/react/Form/Checkbox'

export const SelectAllCheckbox = ({
  id,
  items = [],
  selectedItems = [],
  handleChange = () => {},
  label = '',
}) => {
  const [isAllSelected, setAllSelected] = useState()
  const [isAllUnselected, setAllUnselected] = useState()

  useEffect(() => {
    if (selectedItems.length === 0) {
      setAllUnselected(true)
      setAllSelected(false)
    } else if (items.length === selectedItems.length) {
      setAllUnselected(false)
      setAllSelected(true)
    } else {
      setAllUnselected(false)
      setAllSelected(false)
    }
  }, [items, selectedItems])

  const onChange = () => {
    if (isAllSelected) {
      setAllUnselected(true)
      setAllSelected(false)
      handleClearAll()
    } else if (isAllUnselected) {
      setAllUnselected(false)
      setAllSelected(true)
      handleSetAll()
    } else {
      setAllUnselected(true)
      setAllSelected(false)
      handleClearAll()
    }
  }

  const handleSetAll = () => {
    handleChange(items)
  }
  const handleClearAll = () => {
    handleChange([])
  }
  return (
    <Checkbox
      id={id}
      label={label}
      checked={isAllSelected}
      indeterminate={!isAllSelected && !isAllUnselected}
      onChange={onChange}
      value={1}
    />
  )
}

SelectAllCheckbox.propTypes = {
  id: PropTypes.any,
  items: PropTypes.array,
  selectedItems: PropTypes.array,
  handleChange: PropTypes.func,
  label: PropTypes.string,
}
