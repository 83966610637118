export default class CurrencyFormatter {
  static formatCentsAsCurrency(amount) {
    if (this._isInvalidAmount(amount)) {
      return null
    }
    return this.formatAsCurrency(amount / 100)
  }

  static formatAsCurrency(amount) {
    if (this._isInvalidAmount(amount)) {
      return null
    }
    return Number(amount).toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    })
  }

  static _isInvalidAmount(amount) {
    if (isNaN(amount) || amount === null) {
      return true
    }
    return false
  }
}
