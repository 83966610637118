import { useState, useEffect } from 'react'

export const useErrorBoundary = () => {
  const [exception, setException] = useState()

  useEffect(() => {
    if (exception instanceof Error) {
      throw exception
    }
  }, [exception])

  return setException
}
