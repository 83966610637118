import Entity from './Entity'
import Recipient from './Recipient'
import TransferMetadata from './TransferMetadata'

export default class Transfer extends Entity {
  id
  status
  statusChangedDate

  recipient
  metadata

  constructor(initialValues = {}) {
    super(initialValues)

    const { recipient, metadata } = initialValues

    this.recipient = new Recipient(recipient)
    this.metadata = new TransferMetadata(metadata)
  }
}

export const PENDING = 'pending'
export const ACCEPTED = 'accepted'
