/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { PropTypes } from 'prop-types'
import { Responsive } from 'semantic-ui-react'

import Ticket from '../Ticket/Ticket'

const OrderContent = ({ tickets = new Map(), ticketService = {} }) => {
  const ticketCardsContainerStyles = {
    display: 'inline-block',
    margin: 'auto',
    textAlign: 'center',
  }
  const ticketCardsWideContainerStyles = {
    ...ticketCardsContainerStyles,
    textAlign: 'left',
  }

  const totalTickets = tickets.size
  const ticketCards = []
  tickets.forEach((ticket, ticketId) =>
    ticketCards.push(
      <Ticket
        key={ticketId}
        {...{
          ticket,
          totalTickets,
          ticketIndex: ticketCards.length + 1,
          ticketService,
        }}
      />,
    ),
  )

  const ResponsiveTicketCards = props => {
    // TODO - rework this responsive implementation to be independent of the
    // Semantic components
    return (
      <Responsive as="div" getWidth={getContainerWidth} {...props}>
        {ticketCards.map(ticketCard => (
          <div
            className="card-container"
            key={ticketCard.key}
            style={{
              display: 'inline-block',
              margin: '1rem',
            }}
          >
            {ticketCard}
          </div>
        ))}
      </Responsive>
    )
  }

  const getContainerWidth = () => {
    // TODO - make this use the innerWidth of the order-layout container instead of window
    return (window && window.innerWidth) || 0
  }
  // TODO - rework this to use emotion + a breakpoints config file
  const singleColumnMaxScreenWidth = 615

  return (
    <div
      className="order-layout"
      css={css`
        padding: 0 1rem;
      `}
    >
      <ResponsiveTicketCards
        maxWidth={singleColumnMaxScreenWidth}
        style={ticketCardsContainerStyles}
      />
      <ResponsiveTicketCards
        minWidth={singleColumnMaxScreenWidth + 1}
        style={ticketCardsWideContainerStyles}
      />
    </div>
  )
}

export default OrderContent

OrderContent.propTypes = {
  tickets: PropTypes.shape({ forEach: PropTypes.func }),
  ticketService: PropTypes.object,
}
