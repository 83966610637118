import { Exception } from '../Exception'

const retryMessage = 'Please try again later.'

export class TransferException extends Exception {
  message = `${retryMessage}`
}

export class AcceptTransferException extends TransferException {
  message = `Transfer failed. ${retryMessage}`
}

export class AcceptTransferCanceledException extends TransferException {
  message = `Transfer has been canceled by the ticket holder. Unable to complete transfer.`
}

export class ResendTransferException extends TransferException {
  message = `Resend attempt failed. ${retryMessage}`
}

export class CancelTransferException extends TransferException {
  message = `Cancel attempt failed. ${retryMessage}`
}
