import React from 'react'
import { PropTypes } from 'prop-types'
import { Image } from 'Elevate/adapters/presenters/react/Image'
import appleWalletImage from './images/add-to-apple-wallet.png'
import { useTicketService } from './hooks/tickets'

export const AddToAppleWallet = ({ ticketId, apiUrl }) => {
  const altText = 'Add to Apple Wallet'
  const ticketService = useTicketService()

  return (
    <span
      tabIndex="0"
      onKeyPress={(event, ...args) => {
        var code = event.charCode || event.keyCode
        if (code === 13) {
          ticketService.downloadPkPass(ticketId)
        }
      }}
      onClick={() => ticketService.downloadPkPass(ticketId)}
    >
      <Image src={appleWalletImage} alt={altText} />
    </span>
  )
}

AddToAppleWallet.propTypes = {
  ticketId: PropTypes.string,
  apiUrl: PropTypes.string,
}
