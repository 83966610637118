import React from 'react'
import { ErrorBoundary } from './ErrorBoundary'
import { AccessException } from './Exception'
import { AuthenticationPage } from './Pages'

export class AccessErrorBoundary extends ErrorBoundary {
  render() {
    const { error } = this.state

    if (error) {
      switch (error.constructor) {
        case AccessException:
          return <AuthenticationPage />
        default:
          throw error
      }
    }

    return this.props.children || null
  }
}
