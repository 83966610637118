/** @jsx jsx */
import { jsx } from '@emotion/core'
import { PropTypes } from 'prop-types'
import { LabeledGridRow } from './LabeledGridRow'
import { longUrl } from './TicketBackContent.style'
import { Grid } from 'Elevate/adapters/presenters/react/Grid'

const TicketBackContent = ({
  orderId,
  attendee,
  description,
  eventUrl,
  price,
  termsUrl,
  legal,
}) => {
  return (
    <Grid>
      <LabeledGridRow label="Order Number">{orderId}</LabeledGridRow>
      <LabeledGridRow label="Ordered By">{attendee}</LabeledGridRow>
      {description ? (
        <LabeledGridRow label="Description">{description}</LabeledGridRow>
      ) : null}
      {eventUrl ? (
        <LabeledGridRow label="Event Website" css={longUrl}>
          <a href={eventUrl} title={eventUrl}>
            {eventUrl}
          </a>
        </LabeledGridRow>
      ) : null}
      {price ? (
        <LabeledGridRow label="Ticket Price">{price}</LabeledGridRow>
      ) : null}
      <LabeledGridRow label="Ticket Support">
        <a href="https://support.festivalticketing.com">
          support.festivalticketing.com
        </a>
        <br />
        <a href="mailto:support@elevatetickets.com">
          support@elevatetickets.com
        </a>
      </LabeledGridRow>

      <LabeledGridRow label="Terms and Conditions" css={longUrl}>
        {termsUrl ? <a href={termsUrl}>{termsUrl}</a> : null}
        <br />
        {legal}
      </LabeledGridRow>
    </Grid>
  )
}

export default TicketBackContent

TicketBackContent.propTypes = {
  orderId: PropTypes.string,
  attendee: PropTypes.string,
  description: PropTypes.string,
  eventUrl: PropTypes.string,
  price: PropTypes.string,
  termsUrl: PropTypes.string,
  legal: PropTypes.string,
}
