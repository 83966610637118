/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { PropTypes } from 'prop-types'
import { Label as SemanticLabel } from 'semantic-ui-react'

export const Valuebox = ({ children }) => {
  return (
    <SemanticLabel
      css={css`
        float: right;
        .ui.label& {
          line-height: 0.4rem;
          margin-left: 1.5rem;
        }
      `}
    >
      {children}
    </SemanticLabel>
  )
}

Valuebox.propTypes = {
  children: PropTypes.node,
}
