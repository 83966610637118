/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { PropTypes } from 'prop-types'

import Ticket from '../../../../entities/Ticket'
import { Table } from 'Elevate/adapters/presenters/react/Table/Table'
import { Checkbox } from 'Elevate/adapters/presenters/react/Form/Checkbox'

export const TransferTicketRow = ({
  ticket = new Ticket(),
  selectedTickets = new Map(),
  setSelectedTickets = () => {},
  handleSelectTicket = () => {},
}) => {
  const {
    id: ticketId,
    credential: { name: credentialName },
    seating = {},
  } = ticket

  let { barcode: secondaryContent } = ticket
  if (seating.seat !== undefined) {
    secondaryContent = `Section ${seating.section} Row ${seating.row} Seat ${seating.seat}`
  }

  return (
    <Table.Row key={ticketId}>
      <Table.Cell textAlign="left">
        <Checkbox
          id={ticketId}
          label={credentialName}
          value={ticketId}
          checked={selectedTickets.has(ticketId)}
          disabled={ticket.hasTransfer()}
          onChange={(event, element) => {
            if (!element.disabled) {
              handleSelectTicket(
                element.checked,
                selectedTickets,
                ticket,
                setSelectedTickets,
              )
            }
          }}
        />
      </Table.Cell>
      <Table.Cell textAlign="right">
        <SecondaryContent>{secondaryContent}</SecondaryContent>
      </Table.Cell>
    </Table.Row>
  )
}

TransferTicketRow.propTypes = {
  ticket: PropTypes.instanceOf(Ticket),
  selectedTickets: PropTypes.shape({
    has: PropTypes.func,
    set: PropTypes.func,
    delete: PropTypes.func,
  }),
  setSelectedTickets: PropTypes.func,
  handleTicketSelect: PropTypes.func,
}

const SecondaryContent = ({ children }) => (
  <div
    css={css`
      font-size: 0.9rem;
      color: #aaa;
    `}
  >
    {children}
  </div>
)

SecondaryContent.propTypes = {
  children: PropTypes.node,
}
