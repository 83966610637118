import ClassDecorator from './ClassDecorator'
import HttpAdapter from 'MyAccount/adapters/drivers/HttpAdapter'
import AuthenticationRepository from 'MyAccount/adapters/repositories/AuthenticationRepository'
import TicketRepository from 'MyAccount/adapters/repositories/TicketRepository'
import AuthenticationService from 'MyAccount/services/AuthenticationService'
import TicketService from 'MyAccount/services/TicketService'
import { CartRepository } from 'MyAccount/adapters/repositories/CartRepository'
import { CartService } from 'MyAccount/services/CartService'

class AppContext {
  constructor({ env }) {
    this.authenticationServiceUrl = env.REACT_APP_AUTH_SERVICE_URL
    this.authenticationService = buildAuthenticationService({
      serverUrl: this.authenticationServiceUrl,
    })

    this.ticketServiceUrl = env.REACT_APP_TICKET_SERVICE_URL
    this.ticketService = buildTicketService({
      serverUrl: this.ticketServiceUrl,
      authenticationService: this.authenticationService,
    })

    this.cartService = buildCartService({
      serverUrl: this.ticketServiceUrl,
    })

    this.isInitialized = true
  }

  updateBackgroundImage(imageUrl) {
    if (imageUrl) {
      document.documentElement.style.backgroundImage = `url('${imageUrl}')`
    } else {
      document.documentElement.style.backgroundImage = null
    }
  }
}
export default ClassDecorator.makeSingleton(AppContext)

function buildAuthenticationService({ serverUrl }) {
  const httpAdapter = new HttpAdapter(serverUrl)
  const authenticationRepository = new AuthenticationRepository(httpAdapter)
  return new AuthenticationService(authenticationRepository)
}

function buildTicketService({ serverUrl, authenticationService }) {
  const httpAdapter = new HttpAdapter(serverUrl, () =>
    authenticationService.getAccessToken(),
  )
  const ticketRepository = new TicketRepository(httpAdapter)
  return new TicketService(ticketRepository, httpAdapter)
}

function buildCartService({ serverUrl }) {
  const httpAdapter = new HttpAdapter(serverUrl)
  const cartRepository = new CartRepository(httpAdapter)
  return new CartService(cartRepository)
}
