/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Fragment, useState } from 'react'
import { PropTypes } from 'prop-types'

import { Table } from 'Elevate/adapters/presenters/react/Table/Table'
import {
  createArrayOfKeysFromMap,
  createMapFromArrayOfObjects,
  createIntersectionOfArrays,
} from 'Elevate/services/functions'
import { Toggle } from '../Toggle'
import { Segment } from 'Elevate/adapters/presenters/react/Segment'
import { SelectAllCheckbox } from 'Elevate/adapters/presenters/react/Form/SelectAllCheckbox.js'
import { TransferTicketRows } from './TransferTicketRows'

export const TransferGroupRow = ({
  groupKey,
  groupTickets = new Map(),
  selectedTickets = new Map(),
  setSelectedTickets = () => {},
  handleSelectTicket = () => {},
}) => {
  const groupItemsArray = createArrayOfKeysFromMap(
    getNonTransferredTicketsMap(groupTickets),
  )
  const groupItemsSelectedArray = createIntersectionOfArrays(
    groupItemsArray,
    createArrayOfKeysFromMap(selectedTickets),
  )
  const countOfSelectedTickets = groupItemsSelectedArray.length
  const [isExpanded, setIsExpanded] = useState(countOfSelectedTickets > 0)

  const updateSelectedGroupTickets = selected => {
    let updated = new Map(selectedTickets)
    const noneSelected = !selected.length
    const allSelected = selected.length === groupItemsArray.length
    if (noneSelected) {
      groupTickets.forEach((value, key) => {
        updated.delete(key)
      })
      setSelectedTickets(updated)
    } else if (allSelected) {
      selected.forEach(key => {
        if (!updated.has(key)) {
          updated.set(key, groupTickets.get(key))
        }
      })
      setSelectedTickets(updated)
    }
  }

  return (
    <Fragment>
      <Table.Row
        key={groupKey}
        className={css`
          padding-left: 2.8rem;
        `}
      >
        <Table.Cell textAlign="left">{groupKey}</Table.Cell>
        <Table.Cell textAlign="right">
          <span style={{ marginRight: '.8rem' }}>
            {countOfSelectedTickets} of {groupTickets.size}
          </span>
          <Toggle.Circular
            handleToggle={() => setIsExpanded(!isExpanded)}
            toggleState={isExpanded ? 'up' : 'down'}
          />
        </Table.Cell>
      </Table.Row>

      {isExpanded ? (
        <Fragment>
          <Segment basic size="mini">
            <SelectAllCheckbox
              key={selectedTickets}
              {...{
                id: groupKey,
                items: groupItemsArray,
                selectedItems: groupItemsSelectedArray,
                handleChange: updateSelectedGroupTickets,
                label: 'Select All',
              }}
            />
          </Segment>

          <TransferTicketRows
            {...{
              tickets: groupTickets,
              selectedTickets,
              setSelectedTickets,
              handleSelectTicket,
            }}
          />
        </Fragment>
      ) : null}
    </Fragment>
  )
}

TransferGroupRow.propTypes = {
  groupKey: PropTypes.any,
  groupTickets: PropTypes.shape({
    size: PropTypes.number,
    forEach: PropTypes.func,
  }),
  selectedTickets: PropTypes.shape({
    forEach: PropTypes.func,
    size: PropTypes.number,
  }),
  setSelectedTickets: PropTypes.func,
}

function getNonTransferredTicketsMap(tickets) {
  let selectable = [...tickets.values()].filter(ticket => {
    return !ticket.hasTransfer()
  }, [])
  return createMapFromArrayOfObjects(selectable, 'id')
}
