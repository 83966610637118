import React from 'react'
import { ErrorBanner } from '../../../../../Elevate/adapters/presenters/react/ErrorBanner'
import {
  TransferException,
  AcceptTransferException,
  AcceptTransferCanceledException,
  ResendTransferException,
  CancelTransferException,
} from './TransferException'
import { ErrorBoundary } from '../ErrorBoundary'

export class TransferErrorBoundary extends ErrorBoundary {
  render() {
    const { error } = this.state
    const { children } = this.props
    if (error) {
      switch (error.constructor) {
        case AcceptTransferException:
        case AcceptTransferCanceledException:
          return <ErrorWithBanner errorMessage={error.message} />

        case ResendTransferException:
        case CancelTransferException:
        case TransferException:
          return (
            <ErrorWithBanner errorMessage={error.message}>
              {children}
            </ErrorWithBanner>
          )

        default:
          throw error
      }
    }

    return children || null
  }
}

const ErrorWithBanner = ({ errorMessage, children }) => (
  <>
    <ErrorBanner {...{ errorMessage }} />
    {children}
  </>
)
