/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { useState } from 'react'
import { PropTypes } from 'prop-types'
import { useFormik } from 'formik'

import { Form } from 'Elevate/adapters/presenters/react/Form/Form'
import { TextInput } from 'Elevate/adapters/presenters/react/TextInput'
import { Button } from 'Elevate/adapters/presenters/react/Form/Button'
import { ButtonBar } from '../ButtonBar'
import { Container } from '../../../../../Elevate/adapters/presenters/react/Container.js'
import { ContentTitle } from '../ContentTitle'
import {
  TRANSFER_STATE_SELECTION,
  TRANSFER_STATE_CONFIRMATION,
} from './TransferTickets'
import { TicketDropframe } from '../Ticket/TicketDropframe'
import Ticket from '../../../../entities/Ticket'
import { TransferException } from './TransferException'
import { useTicketService } from '../hooks/tickets'

export const TransferTicketsRecipient = ({
  selectedTickets,
  setTransferState,
  recipient = {
    email: '',
    firstName: '',
    lastName: '',
  },
  setRecipient,
  orderReference,
}) => {
  const ticketService = useTicketService()
  const credentialList = []
  const ticketIds = []

  const [errorState, setErrorState] = useState(false)
  selectedTickets.forEach((ticket = new Ticket(), id) => {
    ticketIds.push({ id: ticket.id })
    const { name: credentialName = '' } = ticket.credential
    credentialList.push(<div key={id}>{credentialName}</div>)
  })

  const formik = useFormik({
    initialValues: { ...recipient },
    onSubmit: values => {
      setRecipient({ ...values })
      ticketService
        .transferTickets(
          orderReference,
          ticketIds,
          values.firstName,
          values.lastName,
          values.email,
        )
        .then(() => setTransferState(TRANSFER_STATE_CONFIRMATION))
        .catch(() => setErrorState(true))
    },
  })

  if (errorState) throw new TransferException()

  const [isProcessing, setIsProcessing] = useState()

  return (
    <Container>
      <Form
        onSubmit={() => {
          setIsProcessing(true)
          formik.handleSubmit()
        }}
        loading={isProcessing}
      >
        <ContentTitle>Recipient Details</ContentTitle>
        <TextInput
          id="firstName"
          label="First Name"
          required
          onChange={formik.handleChange}
          value={formik.values.firstName}
        />
        <TextInput
          id="lastName"
          label="Last Name"
          required
          onChange={formik.handleChange}
          value={formik.values.lastName}
        />
        <TextInput
          id="email"
          label="Email Address"
          required
          onChange={formik.handleChange}
          value={formik.values.email}
          type="email"
        />
        <TicketDropframe {...{ selectedTickets }} />
        <div
          css={css`
            text-align: center;
          `}
        >
          <ButtonBar>
            <Button onClick={() => setTransferState(TRANSFER_STATE_SELECTION)}>
              Back
            </Button>
            <Button.Primary disabled={isProcessing} type="submit">
              Send
            </Button.Primary>
          </ButtonBar>
        </div>
      </Form>
    </Container>
  )
}

TransferTicketsRecipient.propTypes = {
  selectedTickets: PropTypes.shape({ forEach: PropTypes.func }),
  setTransferState: PropTypes.func,
  setRecipient: PropTypes.func,
  orderReference: PropTypes.string,
}
