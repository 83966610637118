import queryStringParser from 'query-string'

export default class QueryString {
  _parse = queryStringParser.parse

  constructor(windowContext) {
    this._window = windowContext || window
  }

  getUrlParam(param, queryString) {
    const search = this._getSearch(queryString)
    const paramValue = this._parse(search)[param]
    return paramValue
  }

  _getSearch(queryString) {
    let search
    if (queryString === undefined) {
      search = this._window.location.search
    } else {
      search = queryString
    }
    return search
  }

  stripUrlParam(param, queryString) {
    const search = this._getSearch(queryString)
    const query = this._parse(search)

    let strippedQueryString = ''
    for (const key in query) {
      if (key !== param) {
        strippedQueryString += strippedQueryString ? '&' : '?'
        strippedQueryString += `${key}=${encodeURIComponent(query[key])}`
      }
    }

    return strippedQueryString
  }
}
