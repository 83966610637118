/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { PropTypes } from 'prop-types'
import { Button as SemanticButton } from 'semantic-ui-react'

export const Button = ({ type = 'button', ...props }) => {
  return (
    <SemanticButton
      circular
      {...{
        css: css`
          .ui.button&:not(.primary) {
            background-color: transparent;
          }
        `,
        ...props,
        type,
      }}
    />
  )
}

Button.propTypes = {
  className: PropTypes.string,
  primary: PropTypes.bool,
  fluid: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.node,
  type: PropTypes.string,
  disabled: PropTypes.bool,
}

Button.Primary = props => <Button primary {...props} />
Button.Primary.propTypes = Button.propTypes

Button.Fluid = props => <Button fluid {...props} />
Button.Fluid.propTypes = Button.propTypes
