import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Divider } from 'semantic-ui-react'
import { Exception } from './Exception'
import { Segment } from 'Elevate/adapters/presenters/react/Segment'
import TicketsUnavailable from './TicketsUnavailable'
import { PageDarkCSS } from './Page'

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { error: null, errorInfo: null }
  }

  static getDerivedStateFromError(error) {
    return { error }
  }

  componentDidCatch(error, errorInfo) {
    // we can log the error to a reporting service or whatever else
    // ErrorService.log(error, errorInfo)

    this.setState({
      error: error,
      errorInfo: errorInfo,
    })
  }

  render() {
    const { error } = this.state

    if (error) {
      return error instanceof Exception ? (
        <h1>{error.message}</h1>
      ) : (
        <Fragment>
          <PageDarkCSS />
          <Segment basic size="massive" />
          <Divider hidden />
          <TicketsUnavailable />
        </Fragment>
      )
    }

    return this.props.children || null
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node,
}

export class AppErrorBoundary extends ErrorBoundary {}
