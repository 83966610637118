import React from 'react'
import { PropTypes } from 'prop-types'
import { Loader as SemanticLoader } from 'semantic-ui-react'

export const Loader = ({ children, ...props }) => (
  <SemanticLoader {...props}>{children}</SemanticLoader>
)

Loader.propTypes = {
  active: PropTypes.bool,
  inverted: PropTypes.bool,
  size: PropTypes.oneOf(['massive']),
  children: PropTypes.node,
}
