import axios from 'axios'
import { AccessException } from '../presenters/react/Exception'

export default class HttpAdapter {
  constructor(baseURL, validateAuthentication) {
    this._validateAuthentication = validateAuthentication

    this._provider = axios.create({ baseURL })
    this._provider.interceptors.request.use(
      config => {
        // if ( route is to be authorized) {
        config.headers = this._includeAuthHeader(config.headers)
        // }
        return config
      },
      error => Promise.reject(error),
    )

    this._provider.interceptors.response.use(
      response => response,
      error => {
        if (error.response && error.response.status === 401) {
          throw new AccessException()
        } else {
          throw error
        }
      },
    )
  }

  get(path, { headers } = {}) {
    return this._provider.get(path)
  }

  _includeAuthHeader(headers) {
    if (this._validateAuthentication) {
      try {
        headers = {
          Authorization: `Bearer ${this._validateAuthentication()}`,
          ...headers,
        }
      } catch (e) {
        // TODO: handle error or let it bubble up?
        console.warn(e)
        throw new AccessException()
      }
    }
    return headers
  }

  post(path, body) {
    return this._provider.post(path, body)
  }

  put(path, body) {
    return this._provider.put(path, body)
  }

  async download(
    path,
    { filename = 'tickets.pkpass', headers } = {},
  ) {
    const response = await this._provider({
      url: path,
      method: 'GET',
      responseType: 'blob', // important
      headers,
    })

    var reader = new window.FileReader();
    reader.readAsDataURL(response.data);
    reader.onload = function () {

      const link = document.createElement('a')
      link.href = reader.result
      link.setAttribute('download', filename)
      link.click()
    }
  }
}
