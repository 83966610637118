import Entity from './Entity'

export default class TransferMetadata extends Entity {
  statusChangedDate

  constructor(initialValues = {}) {
    super(initialValues)

    this.statusChangedDate = new Date(this.statusChangedDate)
  }
}
