/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { PropTypes } from 'prop-types'
import { Header } from 'semantic-ui-react'

export const ContentModalSubtitle = ({ children }) => (
  <Header
    css={css`
      .ui.header& {
        color: #838383;
        font-size: 1.1rem;
        font-weight: 400;
      }
    `}
    as="h2"
  >
    {children}
  </Header>
)

ContentModalSubtitle.propTypes = {
  children: PropTypes.node,
}
