import { useEffect, useState } from 'react'
import AppContext from '../../../../services/AppContext'

export const useCartService = () =>
  new AppContext({ env: process.env }).cartService

export const useCartByOrderReference = (orderReference = '') => {
  const cartService = useCartService()
  const [cart, setCart] = useState({})
  const [exception, setException] = useState()

  useEffect(() => {
    let isMounted = true

    cartService
      .getCartByOrderReference(orderReference)
      .catch(e => isMounted && setException(e))
      .then(response => isMounted && setCart(response))

    return () => (isMounted = false)
  }, [cartService, orderReference, setCart])

  if (exception !== undefined) throw new Error('Cart service exception')

  return cart
}
