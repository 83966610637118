/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Fragment } from 'react'
import { useState } from 'react'
import { ErrorImage } from './ErrorImage'
import { CloseIcon } from './CloseImage'
import { Segment } from './Segment'

export const ErrorBanner = ({ errorMessage }) => {
  const [active, setState] = useState(true)

  return active ? (
    <Fragment>
      <ErrorBannerLayout>
        <ErrorIcon />
        <ErrorMessageLayout>
          <CloseErrorBanner
            onClick={() => {
              setState(false)
            }}
          />
          {errorMessage}
        </ErrorMessageLayout>
      </ErrorBannerLayout>
      <Segment basic />
    </Fragment>
  ) : null
}

const ErrorBannerLayout = props => (
  <div
    css={css`
      font-size: 17px;
      color: #aaa;

      background-color: #3b3a3a;
      width: 100%;
      min-height: 5rem;

      display: flex;
    `}
    {...props}
  />
)

const ErrorMessageLayout = props => (
  <div
    css={css`
      margin: 5px;
      display: inline-block;
      flex: 1;
    `}
    {...props}
  />
)

const ErrorIcon = () => (
  <div
    css={css`
      margin: 4px;
      display: inline-block;
      vertical-align: top;
    `}
  >
    <ErrorImage />
  </div>
)

const CloseErrorBanner = onClick => {
  return (
    <div
      {...onClick}
      css={css`
        cursor: pointer;
        float: right;
      `}
    >
      <CloseIcon />
    </div>
  )
}
