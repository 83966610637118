import { useState, useEffect } from 'react'
import { AccessException } from '../Exception'

import AppContext from '../../../../services/AppContext'
import { createMapFromArrayOfObjects } from 'Elevate/services/functions'

export const useTicketService = () =>
  new AppContext({ env: process.env }).ticketService

export const useTicketsByOrderReference = (orderReference = '') => {
  const ticketService = useTicketService()
  const [tickets, setTickets] = useState()
  const [exception, setException] = useState()

  useEffect(() => {
    if (
      orderReference &&
      ticketService &&
      ticketService.getTicketsByOrderReference
    ) {
      ticketService
        .getTicketsByOrderReference(orderReference)
        .catch(error => setException(error))
        .then(tickets => {
          setTickets(createMapFromArrayOfObjects(tickets, 'id'))
        })
    }
  }, [orderReference, ticketService, setTickets])

  if (exception instanceof AccessException) {
    throw exception
  }

  return tickets
}
