import React from 'react'
import { withRouter } from 'react-router-dom'
import { ErrorBanner } from 'Elevate/adapters/presenters/react/ErrorBanner'
import { AuthenticationException } from './Login/AuthenticationException'
import { ErrorBoundary } from './ErrorBoundary'

class AuthErrorBoundaryWithoutRouter extends ErrorBoundary {
  render() {
    const { error } = this.state
    const { children } = this.props

    if (error) {
      switch (error.constructor) {
        case AuthenticationException:
          return (
            <ErrorWithBanner errorMessage={error.message}>
              {children}
            </ErrorWithBanner>
          )

        default:
          throw error
      }
    }

    return children || null
  }
}
export const AuthErrorBoundary = withRouter(AuthErrorBoundaryWithoutRouter)

const ErrorWithBanner = ({ errorMessage, children }) => (
  <>
    <ErrorBanner {...{ errorMessage }} />
    {children}
  </>
)
