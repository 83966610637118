/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { PropTypes } from 'prop-types'

import { Container } from 'Elevate/adapters/presenters/react/Container.js'

export const ButtonBar = ({ children }) => (
  <Container
    css={css`
      text-align: center;
      margin-top: 6rem;
    `}
  >
    {children}
  </Container>
)

ButtonBar.propTypes = {
  children: PropTypes.node,
}
