import React from 'react'
import { PropTypes } from 'prop-types'

import { TransferTicketRow } from './TransferTicketRow'

export const TransferTicketRows = ({
  tickets = new Map(),
  selectedTickets = new Map(),
  setSelectedTickets = () => {},
  handleSelectTicket = () => {},
}) => {
  const rows = []

  if (tickets.size > 0) {
    tickets.forEach((ticket, ticketId) => {
      if (ticket.isTransferable) {
        rows.push(
          <TransferTicketRow
            key={ticketId}
            {...{
              ticket,
              selectedTickets,
              setSelectedTickets,
              handleSelectTicket,
            }}
          />,
        )
      }
    })
  }
  return rows
}

TransferTicketRows.propTypes = {
  tickets: PropTypes.shape({ size: PropTypes.number, forEach: PropTypes.func }),
  selectedTickets: PropTypes.shape({
    forEach: PropTypes.func,
    size: PropTypes.number,
  }),
  setSelectedTickets: PropTypes.func,
  handleSelectTicket: PropTypes.func,
  ticketHasTransfer: PropTypes.func,
}
