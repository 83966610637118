import React from 'react'
import { Card } from 'semantic-ui-react'

const TicketsUnavailable = () => (
  <Card centered>
    <Card.Content>
      <h1>Tickets are not currently available</h1>
    </Card.Content>
  </Card>
)
export default TicketsUnavailable
