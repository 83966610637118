/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Grid as SemanticGrid } from 'semantic-ui-react'
import { PropTypes } from 'prop-types'

export const Grid = props => {
  return <SemanticGrid {...props} />
}

Grid.propTypes = {
  columns: PropTypes.number,
  children: PropTypes.node,
}

Grid.Row = props => <SemanticGrid.Row {...props} />

Grid.Row.propTypes = {
  css: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  className: PropTypes.string,
  children: PropTypes.node,
}

Grid.Column = props => <SemanticGrid.Column {...props} />

Grid.Column.propTypes = {
  textAlign: PropTypes.oneOf(['left', 'center', 'right', 'justified']),
  width: PropTypes.oneOf([2, 3, 4, 5, 6, 8, 16]),
  className: PropTypes.string,
  children: PropTypes.node,
}
