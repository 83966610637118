export default class TicketDateFormatter {
  static formatTicketDates(eventStartDate, eventEndDate) {
    const msPerDay = 24 * 3600 * 1000

    let formattedTicketDates = isNaN(eventStartDate).valueOf()
      ? ''
      : TicketDateFormatter.formatDate(eventStartDate)

    if (
      formattedTicketDates.length &&
      !isNaN(eventEndDate.valueOf()) &&
      eventEndDate - eventStartDate >= msPerDay
    ) {
      formattedTicketDates += ` - ${TicketDateFormatter.formatDate(
        eventEndDate,
      )}`
    }

    return formattedTicketDates
  }

  static formatDate(date) {
    const dateFormatter = new Intl.DateTimeFormat('en-US', {
      day: '2-digit',
      month: '2-digit',
      year: '2-digit',
    })

    let formattedDate

    try {
      let utcDate = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate())
      formattedDate = dateFormatter.format(utcDate)
    } catch (e) {
      formattedDate = date
    }

    return formattedDate
  }
}
