import React from 'react'
import { Image } from 'Elevate/adapters/presenters/react/Image'
import ticketBannerImage from '../images/ticket-banner.png'

const TicketBanner = ({
  src = ticketBannerImage,
  alt = 'Ticket Banner Image',
  ...props
}) => (
  <div
    className="banner"
    style={{
      position: 'relative',
      display: 'block',
      height: '150px',
      width: '100%',
      clear: 'both',
      textAlign: 'center',
      overflow: 'hidden',
      padding: 0,
      backgroundColor: 'rgb(36, 36, 36)',
    }}
  >
    {src ? (
      <Image src={src} alt={alt} style={{ minWidth: '100%' }} {...props} />
    ) : null}
  </div>
)

TicketBanner.propTypes = Image.propTypes

export default TicketBanner
