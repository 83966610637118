import { css } from '@emotion/core'

export const ticketHeaderRow = css`
  background-color: rgb(36, 36, 36);

  .ui.grid>& {
    padding-top: 1.1rem;
  }

  & > * {
    filter: invert(100%);
  }
`
