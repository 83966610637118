import React from 'react'
import PropTypes from 'prop-types'

import { Button } from 'Elevate/adapters/presenters/react/Form/Button'
import { ActionModal } from '../../../../../Elevate/adapters/presenters/react/Modal/ActionModal'
import { ConfirmationMessageBody } from '../../../../../Elevate/adapters/presenters/react/ConfirmationMessageBody'

export const PendingTransferActionModal = ({
  onConfirm,
  onCancel,
  onComplete,
}) => {
  const trigger = (
    <Button fluid primary>
      Resend Transfer
    </Button>
  )
  const content = {
    confirm: {
      title: 'Resend Transfer Email',
      subtitle: 'Do you need to resend a ticket transfer request?',
      labelConfirm: 'Yes, Resend Email',
      onConfirm,
      labelCancel: 'Nevermind',
      onCancel,
    },
    complete: {
      title: 'Transfer Email Sent',
      body: <ConfirmationBody />,
      labelConfirm: 'Done',
      onConfirm: onComplete,
    },
  }
  return <ActionModal {...{ trigger, content }} />
}

PendingTransferActionModal.propTypes = {
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  onComplete: PropTypes.func,
}

const ConfirmationBody = () => {
  return (
    <ConfirmationMessageBody
      message={'We let them know they have tickets waiting to be accepted'}
    />
  )
}
