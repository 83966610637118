import React from 'react'
import * as PropTypes from 'prop-types'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import 'semantic-ui-css/semantic.min.css'
import AppContext from 'MyAccount/services/AppContext'
import {
  AuthProvider,
  useAuthContext,
} from 'MyAccount/adapters/presenters/react/hooks/AuthContext'
import { AccessErrorBoundary } from './MyAccount/adapters/presenters/react/AccessErrorBoundary'
import { AppErrorBoundary } from './MyAccount/adapters/presenters/react/ErrorBoundary'
import {
  LOGIN,
  LOGOUT,
} from './MyAccount/adapters/presenters/react/hooks/authentication'
import {
  AcceptTransferPage,
  AuthenticationPage,
  OrderPage,
  TransferPage,
} from './MyAccount/adapters/presenters/react/Pages'

function App({ container }) {
  return (
    <AppErrorBoundary>
      <AuthProvider>
        <div className="App">{container.isInitialized && <AuthRouter />}</div>
      </AuthProvider>
    </AppErrorBoundary>
  )
}

App.propTypes = {
  container: PropTypes.instanceOf(AppContext),
}

export default App

const AuthRouter = () => {
  const { logoutState, isAuthenticated } = useAuthContext()

  return (
    <BrowserRouter>
      {isAuthenticated && !logoutState ? (
        <AuthenticatedRoutes />
      ) : (
        <AuthenticationPage action={logoutState ? LOGOUT : LOGIN} />
      )}
    </BrowserRouter>
  )
}

const AuthenticatedRoutes = () => {
  return (
    <AccessErrorBoundary>
      <Switch>
        <Route
          path="/orders/:orderReference/tickets/transfer/:transferParam?"
          render={({ match }) => <TransferPage />}
        />
        <Route
          path="/orders/:orderReference/tickets"
          render={({ match }) => (
            <OrderPage orderReference={match.params.orderReference} />
          )}
          exact
        />
        <Route
          path="/tickets/transfer/:ticketTransferId/accept"
          render={({ match }) => (
            <AcceptTransferPage
              ticketTransferId={match.params.ticketTransferId}
            />
          )}
          exact
        />
      </Switch>
    </AccessErrorBoundary>
  )
}

AuthenticatedRoutes.propTypes = {
  logoutState: PropTypes.bool,
}
