import Cookies from 'universal-cookie'

const ACCESS_TOKEN_COOKIE = 'Access-Token'
const accessTokenCookieOptions = {
  path: '/',
}

export default class AuthenticationService {
  _accessToken

  constructor(repository) {
    this._repository = repository
    this._cookies = new Cookies()
  }

  async login(email, password) {
    try {
      this._accessToken = await this._repository.getAccessToken(email, password)
      this.setAccessToken(this._accessToken)
    } catch (e) {
      this.logout()
      throw e
    }
  }

  logout() {
    this._accessToken = undefined
    this.removeAccessToken()
  }

  setAccessToken(token) {
    this._cookies.set(ACCESS_TOKEN_COOKIE, token, accessTokenCookieOptions)
  }

  removeAccessToken() {
    this._cookies.remove(ACCESS_TOKEN_COOKIE, accessTokenCookieOptions)
  }

  getAccessToken() {
    this._accessToken = this._cookies.get(ACCESS_TOKEN_COOKIE)

    if (!this._accessToken) {
      // TODO create custom exception type?
      throw new Error('Not authenticated')
    }

    return this._accessToken
  }

  isAuthenticated() {
    try {
      this.getAccessToken()
      return true
    } catch (e) {
      return false
    }
  }
}
