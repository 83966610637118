import React from 'react'
import { PropTypes } from 'prop-types'
import { Header } from 'semantic-ui-react'

export const ContentHeader = props => <Header as="h3" {...props} />

ContentHeader.propTypes = {
  size: PropTypes.oneOf(['small']),
  style: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
  ]),
  children: PropTypes.node,
}
