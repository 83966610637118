import React from 'react'
import PropTypes from 'prop-types'
import { TransfersStatusTable } from './TransfersStatusTable'
import { PENDING, ACCEPTED } from '../../../../entities/Transfer'

const TransfersByStatus = ({ tickets = new Map(), status }) => {
  const transfers = new Map()

  if (tickets.size > 0) {
    mapTicketsToTransfers(tickets, transfers, status)
  }

  return (
    <TransfersStatusTable
      {...{ transfers, titlePrefix: statusTitlePrefixes[status] }}
    />
  )
}

const statusTitlePrefixes = {
  [PENDING]: 'Pending',
  [ACCEPTED]: 'Completed',
}

export const PendingTransfers = ({ tickets = new Map() }) => {
  return <TransfersByStatus {...{ tickets, status: PENDING }} />
}

export const CompletedTransfers = ({ tickets = new Map() }) => {
  return <TransfersByStatus {...{ tickets, status: ACCEPTED }} />
}

CompletedTransfers.propTypes = PendingTransfers.propTypes = {
  tickets: PropTypes.shape({
    has: PropTypes.func,
    get: PropTypes.func,
    set: PropTypes.func,
  }),
}

function mapTicketsToTransfers(
  tickets = new Map(),
  transfers = new Map(),
  status,
) {
  tickets.forEach((ticket, ticketId) => {
    // TODO - move this sort of work to an entity or service outside of the UI component context
    if (
      ticket.isTransferable &&
      (!status || ticket.transfer.status === status)
    ) {
      if (transfers.has(ticket.transfer.id)) {
        const transfer = transfers.get(ticket.transfer.id)
        transfer.qty++
        transfer.credentialList.push(ticket.credential.name)
      } else {
        transfers.set(ticket.transfer.id, {
          ...ticket.transfer,
          credentialList: [ticket.credential.name],
          qty: 1,
        })
      }
    }
  })
}
