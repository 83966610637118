/** @jsx jsx */
import { jsx } from '@emotion/core'
import PropTypes from 'prop-types'

import { Button } from 'Elevate/adapters/presenters/react/Form/Button'
import { ActionModal } from '../../../../../Elevate/adapters/presenters/react/Modal/ActionModal'
import { ConfirmationMessageBody } from '../../../../../Elevate/adapters/presenters/react/ConfirmationMessageBody'

export const PendingCancelActionModal = ({
  onConfirm,
  onCancel,
  onComplete,
}) => {
  const trigger = <Button fluid>Cancel Transfer</Button>
  const content = {
    confirm: {
      title: 'Cancel Transfer',
      subtitle: 'Do you need to cancel this ticket transfer?',
      labelConfirm: 'Yes, Cancel Transfer',
      onConfirm,
      labelCancel: 'Nevermind',
      onCancel,
    },
    complete: {
      title: 'Transfer Canceled',
      body: <ConfirmationBody />,
      labelConfirm: 'Done',
      onConfirm: onComplete,
    },
  }
  return <ActionModal {...{ trigger, content }} />
}

PendingCancelActionModal.propTypes = {
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  onComplete: PropTypes.func,
}

const ConfirmationBody = () => {
  return (
    <ConfirmationMessageBody
      message={'We let them know this ticket transfer has been canceled'}
    />
  )
}
