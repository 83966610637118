import React, { useState, useEffect } from 'react'
import { PropTypes } from 'prop-types'
import { Loader } from 'Elevate/adapters/presenters/react/Loader'
import OrderContent from './OrderContent'
import TicketsUnavailable from '../TicketsUnavailable'
import { useAppContext } from 'MyAccount/adapters/presenters/react/hooks/app'
import { useTicketsByOrderReference } from '../hooks/tickets'

const Order = ({ orderReference }) => {
  const [isLoading, setIsLoading] = useState(true)
  const appContext = useAppContext({ updateBackgroundImage: () => {} })
  const { ticketService, updateBackgroundImage } = appContext
  const tickets = useTicketsByOrderReference(orderReference)

  useEffect(() => {
    if (isLoading && tickets) {
      // TODO - figure out a way to tie this background implementation to the Client/Festival/Event context instead
      if (tickets.size > 0) {
        const firstTicket = tickets.entries().next().value[1]
        updateBackgroundImage(firstTicket.webAssets.images['background'])
      }
      setIsLoading(false)
    }
  }, [isLoading, tickets, updateBackgroundImage, setIsLoading])

  if (isLoading) {
    return <Loader active size="massive" />
  } else if (tickets && tickets.size > 0) {
    return <OrderContent {...{ tickets, ticketService }} />
  } else return <TicketsUnavailable />
}
export default Order

Order.propTypes = {
  orderReference: PropTypes.string,
}
