/** @jsx jsx */
import { useState } from 'react'
import { jsx, css } from '@emotion/core'
import { PropTypes } from 'prop-types'
import Index from '../Index'
import { Toggle } from '../Toggle'
import TicketLogo from './TicketLogo'
import TicketBanner from './TicketBanner'
import { ContentHeader } from '../ContentHeader'
import { LabeledGridRow } from './LabeledGridRow'
import ContentLabel from '../ContentLabel'
import { QRCode } from 'Elevate/adapters/presenters/react/QRCode/QRCode'
import { AddToAppleWallet } from '../AddToAppleWallet'
import { ticketHeaderRow } from './TicketFront.style'
import { Grid } from 'Elevate/adapters/presenters/react/Grid'
import { Mask } from 'Elevate/adapters/presenters/react/Mask'
import { TransferStatusModal } from '../TicketTransfer/TransferStatusModal'
import Transfer, { PENDING, ACCEPTED } from '../../../../entities/Transfer'
import PrintTicket from './PrintTicket'
import { useTicketService } from '../hooks/tickets'
import CurrencyFormatter from '../../../../services/CurrencyFormatter'

const VALID_TRANSFER_STATUSES = [PENDING, ACCEPTED]

const TicketFront = ({
  id,
  credentialName,
  eventName,
  venueName,
  eventPresenter,
  eventDates,
  seating,
  barcode,
  apiUrl,
  ticketIndex,
  totalTickets,
  setShowBack,
  showBack,
  isIOSDevice,
  orderReference,
  images = {},
  isTransferable,
  allowPrintingMobileTickets,
  transfer = {},
  showPrint,
  setShowPrint,
  printable,
}) => {

    let beforePrint = function() {};
    let afterPrint = function() {};
    if (window.matchMedia) {
        let mediaQueryList = window.matchMedia('print');
        mediaQueryList.addListener(function(mql) {
            if (mql.matches) {
                beforePrint();
            } else {
                afterPrint();
            }
        });
    }

    const ticketService = useTicketService()
    const [items, setItems] = useState({});

    const handlePrint = () => {
        setShowPrint(true);
        makeTicketPrintable();
    }

    const makeTicketPrintable = () => {
        ticketService
          .getTicketById(id)
          .catch(() => {})
          .then(data => addTermsAndPrint(data))
    }

    const addTermsAndPrint = (data) => {
        setItems(data);
        let legalExists = document.querySelector('.legal');
        if(legalExists){ legalExists.remove(); }
        let legalDetailsForPrint = data.event.legal;
        let legalDetails = document.createElement('div');
            legalDetails.classList.add('legal','row');
        let legalDetailsColumn = document.createElement('div');
            legalDetailsColumn.classList.add('left','aligned','sixteen','wide','column');
        let legalDetailsHeader = document.createElement('div');
            legalDetailsHeader.classList.add('ui','sub','header');
        let printTicket = document.querySelector('.ticket-' + data.id);
        legalDetails.append(legalDetailsColumn);
        legalDetailsColumn.append(legalDetailsHeader);
        legalDetailsHeader.append('TERMS & CONDITIONS');
        legalDetailsColumn.append(legalDetailsForPrint);
        printTicket.append(legalDetails);

        window.print();
        afterPrint = () => {
            let hasLegal = document.querySelector('.legal');
            if(hasLegal){ hasLegal.remove(); }
            setShowPrint(false);
        }
    }

  return (
    <div>
      {renderTransferStatusModal(transfer, orderReference)}
        <Grid columns={2}>
          <Grid.Row>
            <Grid.Column columns={2}>
              <TicketLogo src={images.logo} />
            </Grid.Column>
            <Grid.Column columns={1} textAlign="right" verticalAlign='middle'>
                {allowPrintingMobileTickets && (
                  <PrintTicket ticketId={id} printable={printable} handlePrint={handlePrint}/>
                )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Grid columns={1} divided>
        <TicketBanner className="banner" src={images.strip} />
        <Grid.Row className="event-header" css={ticketHeaderRow}>
          <Grid.Column textAlign="left">
            <ContentHeader>{credentialName}</ContentHeader>
          </Grid.Column>
        </Grid.Row>
        {seating ? <Seating {...seating} /> : null}
        {showPrint ? <PrintLayout {...items} /> : null}
        <LabeledGridRow label="Event">
          {`${eventName} at ${venueName}`}
          {eventPresenter ? ` presented by ${eventPresenter}` : null}
        </LabeledGridRow>
        <LabeledGridRow label="Event Dates">{eventDates}</LabeledGridRow>
        <CenterLayout className="barcode">
          <QRCode barcode={barcode} />
        </CenterLayout>
        {isIOSDevice && (
          <LabeledGridRow textAlign="center">
            <AddToAppleWallet ticketId={id} apiUrl={apiUrl} />
          </LabeledGridRow>
        )}
      </Grid>
      <TicketControls
        {...{
          ticketIndex,
          totalTickets,
          setShowBack,
          showBack,
          orderReference,
          id,
          isTransferable,
        }}
      />
    </div>
  )
}

const Seating = ({ section, row, seat }) => {
  const columnWidth = 5
  return (
    <Grid.Row>
      <Grid.Column textAlign="left" width={columnWidth}>
        <ContentLabel>Section</ContentLabel>
        {section}
      </Grid.Column>
      <Grid.Column textAlign="center" width={columnWidth + 1}>
        <ContentLabel>Row</ContentLabel>
        {row}
      </Grid.Column>
      <Grid.Column textAlign="right" width={columnWidth}>
        <ContentLabel>Seat</ContentLabel>
        {seat}
      </Grid.Column>
    </Grid.Row>
  )
}

const PrintLayout = ({
  order,
  attendee,
  description,
  price,
}) => {
  return (
    <Grid className="order-details">
      <LabeledGridRow label="Order Number">{order ? order.id : null}</LabeledGridRow>
      <LabeledGridRow label="Ordered By">{attendee}</LabeledGridRow>
      {description ? (
        <LabeledGridRow label="Description">{description}</LabeledGridRow>
      ) : null}
      {price ? (
        <LabeledGridRow label="Ticket Price">{CurrencyFormatter.formatCentsAsCurrency(price)}</LabeledGridRow>
      ) : null}
    </Grid>
  )
}

const CenterLayout = props => (
  <div
    css={css`
      margin: 0 auto;
      text-align: center;
    `}
    {...props}
  />
)

const TicketControls = ({
  ticketIndex,
  totalTickets,
  setShowBack,
  showBack,
  orderReference,
  id,
  isTransferable,
}) => (
  <Grid className="ticket-details">
    <Grid.Row>
      <Grid.Column width={5} align="left">
        <Index currentIndex={ticketIndex} total={totalTickets} />
      </Grid.Column>
      <Grid.Column width={6} align="center">
        {isTransferable && (
          <a href={`/orders/${orderReference}/tickets/transfer/${id}`}>
            Transfer
          </a>
        )}
      </Grid.Column>
      <Grid.Column width={5} align="right" className="toggle-back">
        <Toggle
          handleToggle={() => setShowBack(!showBack)}
          toggleState={showBack ? 'up' : 'down'}
        />
      </Grid.Column>
    </Grid.Row>
  </Grid>
)

const renderTransferStatusModal = (transfer, orderReference) =>
  isTicketInTransferStatus(transfer) ? (
    <Mask>
      <TransferStatusModal
        recipient={transfer.recipient}
        orderReference={orderReference}
        status={transfer.status}
        metadata={transfer.metadata}
      />
    </Mask>
  ) : null

const isTicketInTransferStatus = transfer => {
  return VALID_TRANSFER_STATUSES.includes(transfer.status)
}

export default TicketFront

TicketFront.propTypes = {
  id: PropTypes.string,
  credentialName: PropTypes.string,
  eventName: PropTypes.string,
  eventPresenter: PropTypes.string,
  eventDates: PropTypes.string,
  venueName: PropTypes.string,
  seating: PropTypes.object,
  barcode: PropTypes.string,
  apiUrl: PropTypes.string,
  images: PropTypes.object,
  isTransferable: PropTypes.bool,
  allowPrintingMobileTickets: PropTypes.bool,
  transfer: PropTypes.instanceOf(Transfer),
}
