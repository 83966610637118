/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Button } from 'Elevate/adapters/presenters/react/Form/Button'
import { Card } from 'Elevate/adapters/presenters/react/Card/Card'
import Recipient from '../../../../entities/Recipient'
import TicketDateFormatter from '../../../../services/TicketDateFormatter'
import { PENDING, ACCEPTED } from '../../../../entities/Transfer'

export const TransferStatusModal = ({
  recipient: { firstName = '', lastName = '' } = {},
  orderReference,
  status = '',
  metadata = {},
}) => {
  let statusDisplay = status.toUpperCase()
  const formattedDate = TicketDateFormatter.formatDate(
    metadata.statusChangedDate,
  )

  if (metadata.statusChangedDate && status === ACCEPTED) {
    statusDisplay += ` (${formattedDate})`
  }

  return (
    <CardLayout
      meta={`TRANSFER ${statusDisplay}`}
      description={
        <div>
          {`${firstName} ${lastName}`}
          {status === PENDING && <ButtonBar {...{ orderReference }} />}
        </div>
      }
    />
  )
}

const CardLayout = props => (
  <Card
    css={css`
      text-align: left;
      line-height: 2rem;
      & .meta {
        font-style: italic;
      }
      // TODO - see if we can figure out a better way to handle this sort of thing
      .content .ui.card& {
        margin: 240px auto auto auto;
        width: 230px;
        color: black;
      }
    `}
    {...props}
  />
)
TransferStatusModal.propTypes = {
  recipient: PropTypes.instanceOf(Recipient),
  orderReference: PropTypes.string,
}

const ButtonBar = ({ orderReference }) => (
  <ButtonBarLayout>
    <Link
      to={`/orders/${orderReference}/tickets/transfer/pending`}
      tabIndex={-1}
    >
      <Button.Primary fluid>Manage Transfer</Button.Primary>
    </Link>
  </ButtonBarLayout>
)

const ButtonBarLayout = ({ children }) => (
  <div
    css={css`
      margin-top: 2rem;
    `}
  >
    {children}
  </div>
)
