import React, { useState, useEffect } from 'react'
import { PropTypes } from 'prop-types'
import { Segment } from 'Elevate/adapters/presenters/react/Segment'
import { Loader } from 'Elevate/adapters/presenters/react/Loader'

import TicketBackContent from './TicketBackContent'

const TicketBack = ({
  isLoading,
  fetchData,
  orderId,
  attendee,
  description,
  eventUrl,
  price,
  termsUrl,
  legal,
}) => {
  const [loadedOnce, setLoadedOnce] = useState(false)

  useEffect(() => {
    if (!loadedOnce && !orderId) {
      fetchData()
      setLoadedOnce(true)
    }
  }, [fetchData, loadedOnce, orderId])

  if (isLoading === true) {
    return (
      <Segment basic>
        <Loader active />
      </Segment>
    )
  } else if (orderId) {
    return (
      <TicketBackContent
        orderId={orderId}
        attendee={attendee}
        description={description}
        eventUrl={eventUrl}
        price={price}
        termsUrl={termsUrl}
        legal={legal}
      />
    )
  } else if (isLoading === false) {
    return (
      <div>
        {/*TODO - consider adding a retry link here*/}
        Additional ticket data could not be loaded. Please check your Internet
        connection and try again.
      </div>
    )
  } else return <Segment basic />
}

export default TicketBack

TicketBack.propTypes = {
  isLoading: PropTypes.bool,
  fetchData: PropTypes.func,
  orderId: PropTypes.string,
  attendee: PropTypes.string,
  description: PropTypes.string,
  eventUrl: PropTypes.string,
  price: PropTypes.string,
  termsUrl: PropTypes.string,
  legal: PropTypes.string,
}
