import React from 'react'
import { PropTypes } from 'prop-types'

import { Container as SemanticContainer } from 'semantic-ui-react'

export const Container = props => {
  return <SemanticContainer fluid {...props} />
}

Container.propTypes = {
  textAlign: PropTypes.string,
  children: PropTypes.node,
}
