import React from 'react'
import { PropTypes } from 'prop-types'

import { Card as SemanticCard } from 'semantic-ui-react'

export const Card = props => {
  return <SemanticCard raised {...props} />
}

Card.propTypes = {
  meta: PropTypes.string,
  description: PropTypes.node,
}

Card.Content = ({ children, ...props }) => (
  <SemanticCard.Content {...props}>{children}</SemanticCard.Content>
)

Card.Content.propTypes = Card.propTypes
