/** @jsx jsx */
import { jsx, css, Global as GlobalCSS } from '@emotion/core'
import { Fragment, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Divider } from 'semantic-ui-react'
import { AppMenu } from './AppMenu/AppMenu'
import { Loader } from 'Elevate/adapters/presenters/react/Loader'

//TODO - rework things to grab orderReference from an app or page context hook
//rather than passing this value through every layer
export const Page = ({
  dark = false,
  narrow = false,
  appMenu = true,
  orderReference,
  children,
}) => {
  const [doRefresh, setRefreshState] = useState()
  const history = useHistory()
  const location = useLocation()

  const refreshPage = pathname => {
    if (pathname === location.pathname.slice(0, pathname.length)) {
      setRefreshState(true)
      history.push(pathname)
      history.go(0)
    }
  }

  return (
    <PageContent
      {...{
        narrow,
        dark,
        appMenu,
        orderReference,
        doRefresh,
        refreshPage,
      }}
    >
      {children}
    </PageContent>
  )
}

Page.propTypes = {
  dark: PropTypes.bool,
  orderReference: PropTypes.string,
  children: PropTypes.node,
}

const PageContent = ({
  narrow,
  dark,
  appMenu,
  refreshPage,
  doRefresh,
  orderReference,
  children,
}) => (
  <Fragment>
    {dark === true ? <PageDarkCSS /> : null}
    {appMenu === true ? (
      <Fragment>
        <AppMenu {...{ orderReference, refreshPage, doRefresh }} />
        <Divider hidden />
      </Fragment>
    ) : null}
    {doRefresh === true ? (
      <Loader active size="massive" />
    ) : (
      <div
        className="page-content"
        css={css`
          margin: auto;
          /* TODO: update this to reference the width from a global theme or something */
          ${narrow === true ? `max-width: 420px;` : null}
        `}
      >
        {children}
      </div>
    )}
    <Divider hidden />
  </Fragment>
)

export const PageDarkCSS = () => {
  return (
    <GlobalCSS
      styles={css`
        html {
          background-color: rgb(53, 53, 53);
        }
        body {
          background-color: transparent;
        }
      `}
    />
  )
}

Page.Narrow = props => <Page narrow {...props} />
Page.Narrow.propTypes = Page.propTypes
