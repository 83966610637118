/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { PropTypes } from 'prop-types'
import { Header } from 'semantic-ui-react'

export const ContentModalTitle = ({ children }) => (
  <Header
    css={css`
      .ui.header& {
        color: #555353;
        font-size: 1.5rem;
        font-weight: 900;
        margin-top: 1rem;
      }
    `}
    as="h1"
  >
    {children}
  </Header>
)

ContentModalTitle.propTypes = {
  children: PropTypes.node,
}
