export default class TicketService {
  constructor(repository, http) {
    this._repository = repository
    this._http = http
  }

  getTicketsByOrderReference(orderReference) {
    return this._search({ orderReference })
  }

  _search(query = {}) {
    return this._repository.search(query)
  }

  getTicketById(id) {
    return this._find({ id })
  }

  _find(query = {}) {
    return this._repository.find(query)
  }

  transferTickets(sourceId, tickets, firstName, lastName, email) {
    return this._http.post(`/transfers`, {
      ...{ sourceId, tickets, recipient: { firstName, lastName, email } },
    })
  }

  acceptTransfer(ticketTransferId, transferStatus) {
    return this._http.put(
      `/transfers/${ticketTransferId}/status/${transferStatus}`,
    )
  }

  cancelTransfer(ticketTransferId, transferStatus = 'canceled') {
    return this._http.put(
      `/transfers/${ticketTransferId}/status/${transferStatus}`,
    )
  }

  resendTransferEmail(ticketTransferId) {
    return this._http.put(`/transfers/${ticketTransferId}/status/pending`)
  }

  downloadPkPass(id) {
    const pkpassType = 'application/vnd.apple.pkpass'

    return this._http.download(`/${id}`, {
      filename: 'Ticket.pkpass',
      headers: { Accept: pkpassType },
    })
  }
}
