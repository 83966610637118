/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'

import { PENDING } from '../../../../entities/Transfer'
import {
  Table,
  TableHeader,
} from 'Elevate/adapters/presenters/react/Table/Table'
import { Toggle } from '../Toggle'
import ArraySummary from '../../../../services/ArraySummary'
import TicketDateFormatter from '../../../../services/TicketDateFormatter'
import { ContentTitle } from '../ContentTitle'
import { ContentSubtitle } from '../ContentSubtitle'
import ContentLabel from '../ContentLabel'
import { PendingTransferActionModal } from './PendingTransferActionModal'
import { PendingCancelActionModal } from './PendingCancelActionModal'
import { ButtonBar } from '../ButtonBar.js'
import {
  ResendTransferException,
  CancelTransferException,
} from './TransferException'
import { useTicketService } from '../hooks/tickets'
import { Container } from '../../../../../Elevate/adapters/presenters/react/Container'

export const TransfersStatusTable = ({ titlePrefix = 'Ticket', transfers }) => {
  const ticketService = useTicketService()
  const rows = generateTransferStatusTableRows(ticketService, transfers)

  return (
    <Fragment>
      <Container>
        <ContentTitle>{`${titlePrefix} Transfers`}</ContentTitle>
        {rows.length > 0 ? null : (
          <ContentSubtitle>
            You do not have any {titlePrefix.toLowerCase()} transfers
          </ContentSubtitle>
        )}
      </Container>
      {rows.length > 0 ? (
        <Fragment>
          <TransfersStatusTableHeader />
          <Table>
            <Table.Body>{rows}</Table.Body>
          </Table>
        </Fragment>
      ) : null}
    </Fragment>
  )
}

TransfersStatusTable.propTypes = {
  titlePrefix: PropTypes.string,
  transfers: PropTypes.shape({
    size: PropTypes.number,
    forEach: PropTypes.func,
  }),
}

function generateTransferStatusTableRows(ticketService, transfers = new Map()) {
  const rows = []

  if (transfers.size > 0) {
    transfers.forEach(transfer =>
      rows.push(
        <TransferRow key={transfer.id} {...{ ticketService, transfer }} />,
      ),
    )
  }

  return rows
}

const columnWidths = {
  recipient: '21rem',
  ticketQty: '5rem',
  expander: '3rem',
}

const TransfersStatusTableHeader = () => (
  <TableHeader
    className={css`
      & div {
        display: table-cell;
        font-weight: bold;
        white-space: nowrap;
      }
    `}
  >
    <div
      css={css`
        width: ${columnWidths.recipient};
      `}
    >
      Recipient
    </div>
    <div
      css={css`
        width: ${columnWidths.ticketQty};
        text-align: right;
      `}
    >
      Ticket Qty
    </div>
    <div
      css={css`
        width: ${columnWidths.expander};
      `}
    ></div>
  </TableHeader>
)

const TransferRow = props => {
  const [isExpanded, setIsExpanded] = useState(false)

  return <Row {...{ ...props, isExpanded, setIsExpanded }} />
}

export const Row = ({ ticketService, transfer, isExpanded, setIsExpanded }) => {
  const {
    qty,
    recipient: { email, firstName, lastName },
    metadata: { statusChangedDate },
    credentialList,
  } = transfer

  const history = useHistory()

  return (
    <Table.Row>
      <Table.Cell textAlign="left">
        <div
          css={css`
            display: table-cell;
            width: ${columnWidths.recipient};
          `}
        >
          {firstName + ' ' + lastName}
        </div>
        <div
          css={css`
            display: table-cell;
            width: ${columnWidths.ticketQty};
            text-align: right;
          `}
        >
          {qty}
        </div>
        <div
          css={css`
            display: table-cell;
            width: ${columnWidths.expander};
            text-align: right;
          `}
        >
          <Toggle.Circular
            handleToggle={() => setIsExpanded(!isExpanded)}
            toggleState={isExpanded ? 'up' : 'down'}
          />
        </div>
        {isExpanded && (
          <ExpandedRowContent
            {...{ credentialList, email, dateSent: statusChangedDate }}
          >
            {transfer.status === PENDING ? (
              <ButtonBar>
                <PendingTransferActionModal
                  onConfirm={onResend.bind(this, ticketService, transfer.id)}
                  onComplete={() => onResendDone(history)}
                />
                <PendingCancelActionModal
                  onConfirm={onCancelTransfer.bind(
                    this,
                    ticketService,
                    transfer.id,
                  )}
                  onComplete={() => onCancelDone(history)}
                />
              </ButtonBar>
            ) : null}
          </ExpandedRowContent>
        )}
      </Table.Cell>
    </Table.Row>
  )
}

Row.propTypes = {
  transfer: PropTypes.object,
  isExpanded: PropTypes.bool,
  setIsExpanded: PropTypes.func,
}

const ExpandedRowContent = ({ credentialList, email, dateSent, children }) => {
  const credentialListSummary = new ArraySummary().getFormattedWithQuantityArray(
    credentialList,
  )

  return (
    <div
      css={css`
        background-color: white;
        margin: 1rem -0.7rem -0.85rem;
        padding: 0.7rem;
        border-style: solid;
        border-width: 1px 0;
        border-color: rgba(34, 36, 38, 0.1);
      `}
    >
      <ContentLabel>Tickets</ContentLabel>
      {credentialListSummary.map(credentialSummary => (
        <div key={credentialSummary}>{credentialSummary}</div>
      ))}
      <ContentLabel>Email</ContentLabel>
      {email}
      <ContentLabel>Date Sent</ContentLabel>
      {TicketDateFormatter.formatDate(dateSent)}
      {children}
    </div>
  )
}

const onResend = async (ticketService, id) => {
  let response
  try {
    response = await ticketService.resendTransferEmail(id)
  } catch (e) {
    throw new ResendTransferException()
  }
  if (response.status !== 200) {
    throw new ResendTransferException()
  }
  return true
}

const onResendDone = history => {
  history.go(0)
}

const onCancelTransfer = async (ticketService, id) => {
  let response
  try {
    response = await ticketService.cancelTransfer(id)
  } catch (e) {
    throw new CancelTransferException()
  }
  if (response.status !== 200) {
    throw new CancelTransferException()
  }
  return true
}

const onCancelDone = history => {
  history.go(0)
}
